import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { StoreProvider } from "easy-peasy";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import { Button } from "@material-ui/core";
import store from "./store/index";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
// window.LOG_LEVEL='DEBUG';
Amplify.configure(awsconfig);

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <StoreProvider store={store}>
    <SnackbarProvider
      maxSnack={10}
      action={(key) => (
        <Button
          variant="outlined"
          color="inherit"
          onClick={onClickDismiss(key)}
        >
          Dismiss
        </Button>
      )}
      ref={notistackRef}
    >
      <SnackbarUtilsConfigurator />
      <App />
    </SnackbarProvider>
  </StoreProvider>
);
