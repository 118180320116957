import React, {useEffect} from "react";
import { useStoreState } from "easy-peasy";
import "./UserName.scss";

export default function UserPanel() {
  const userName = useStoreState(state => state.user).split("-")[1];
  useEffect(() => {

  }, [userName])
  return (
    <div className="user-name">
       account: {userName}
    </div>
  );
}
