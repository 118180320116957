import { action, thunk, persist } from "easy-peasy";
// Renommer dans projectsService _deleteTurbine etc... avec underscore pour lisibilité
import {
  getProjects,
  addProject,
  deleteProject,
  getLocationId,
  addAOI,
  deleteAOI,
  addTurbine,
  deleteTurbine,
  addConfiguration,
  // addClonedConfiguration,
  deleteConfiguration,
  addRiskZone,
  deleteRiskZone,
  reactivateAOI,
  renameAOI,
  renameTurbine,
  renameConfiguration,
  renameRiskZone,
} from "../service/projectsService";

import { snackActions } from "../utils/SnackbarUtils";

const model = {
  auth: {
    isLoggedIn: false,
  },
  setAuthState: action((state, authState) => {
    state.auth.isLoggedIn = authState;
  }),
  user: "",
  setUser: action((state, username) => {
    state.user = username;
  }),
  isLoading: true,
  setIsLoading: action((state, loadingState) => {
    state.isLoading = loadingState;
  }),
  isloadingResults: false,
  setLoadingResults: action((state, loadingState) => {
    state.isLoadingResults = loadingState;
  }),
  isResultsScreenOn: false,
  setResultScreenStatus: action((state, resultScreenState) => {
    state.isResultsScreenOn = resultScreenState;
  }),
  modalStep: [0],
  setModalStep: action((state, step) => {
    state.modalStep = step;
  }),
  lambert: true,
  setLambert: action((state, lambert) => {
    state.lambert = lambert;
  }),
  // Gets set to true if AOI needs to be reactivated
  aoiNeedsReactivation: { needsReactivation: false, aoiId: "", aoiName: "" },
  // Checks if an AOI needs to be reactivated and sets aoiNeedsReactivation value accordingly
  checkIfAoiNeedsReactivation: thunk((actions, payload, helpers) => {
    helpers.getState().projects.forEach((proj) => {
      if (proj.project_id === helpers.getState().currentItem.projectId) {
        proj.areas_of_interest.forEach((aoi) => {
          if (aoi.aoi_id === helpers.getState().currentItem.aoiId) {
            // const oneDay = 24 * 60 * 60 * 1000; // constant to calculate amount of time between two dates
            let today = new Date();
            let dateOfLock = new Date(
              aoi.lock_dates[aoi.lock_dates.length - 1] + "T00:00:00Z"
            );
            // check if lock_date has been reached or not
            if (today < dateOfLock) {
              //const diffDays = Math.round((today - dateOfLock) / oneDay); // ca, be used to find the date difference
              actions.setAoiNeedsReactivationToFalse();
            } else if (today > dateOfLock) {
              actions.setAoiNeedsReactivationToValue({
                needsReactivation: true,
                aoiId: aoi.aoi_id,
                aoiName: aoi.area_name,
              });
            }
          }
        });
      }
    });
  }),
  setAoiNeedsReactivationToValue: action((state, value) => {
    state.aoiNeedsReactivation = value;
  }),
  setAoiNeedsReactivationToFalse: action((state) => {
    state.aoiNeedsReactivation = {
      needsReactivation: false,
      aoiId: "",
      aoiName: "",
    };
  }),
  reactivateAOI: thunk(async (actions, aoiId) => {
    actions.setSidebarIsLoadingProjects(true);
    const res = await reactivateAOI(aoiId);
    if (res.err === false) {
      actions.setCurrentItem(["aoiId", aoiId]);
      actions.setSidebarOpenItems(aoiId);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  // Crucial, used to inject data from existing inputs to each step
  stepData: null,
  setStepData: action((state, id) => {
    if (id === null) {
      state.stepData = null;
    } else {
      state.projects.forEach((proj) => {
        if (proj.project_id === id) {
          state.stepData = proj;
        } else {
          proj.areas_of_interest.forEach((aoi) => {
            if (aoi.aoi_id === id) {
              state.stepData = aoi;
            } else {
              aoi.wind_turbines.forEach((turbine) => {
                if (turbine.wt_id === id) {
                  state.stepData = turbine;
                } else {
                  turbine.configurations.forEach((config) => {
                    if (config.configuration_id === id) {
                      state.stepData = config;
                    } else {
                      config.risk_zones.forEach((zone) => {
                        if (zone.element_id === id) {
                          state.stepData = zone;
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }),
  // Used to display turbine on existing Risk Zone
  turbineCoords: [],
  setTurbineCoords: action((state, payload) => {
    state.projects.forEach((proj) => {
      if (proj.project_id === payload.projectId) {
        proj.areas_of_interest.forEach((aoi) => {
          if (aoi.aoi_id === payload.aoiId) {
            aoi.wind_turbines.forEach((turbine) => {
              if (turbine.wt_id === payload.turbineId) {
                state.turbineCoords = [
                  turbine.turbine_location[0],
                  turbine.turbine_location[1],
                  turbine.hub_height,
                ];
              }
            });
          }
        });
      }
    });
  }),
  // Used to pass AOI coordinates to new wind turbines
  aoiTurbineCoords: [],
  setAoiTurbineCoords: action((state, currentItem) => {
    state.projects.forEach((proj) => {
      if (proj.project_id === currentItem.projectId) {
        proj.areas_of_interest.forEach((aoi) => {
          if (aoi.aoi_id === currentItem.aoiId) {
            state.aoiTurbineCoords = aoi.area_location;
          }
        });
      }
    });
  }),
  currentItem: {
    projectId: null,
    aoiId: null,
    turbineId: null,
    calculationId: null,
    elementId: null,
  },
  setCurrentItem: action((state, payload) => {
    let clone = { ...state.currentItem };
    clone[payload[0]] = payload[1];
    state.currentItem = clone;
  }),
  sidebarIsLoadingProjects: false,
  setSidebarIsLoadingProjects: action((state, payload) => {
    state.sidebarIsLoadingProjects = payload;
  }),
  projects: [],
  setProjects: action((state, payload) => {
    state.projects = payload;
  }),
  getProjects: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const projects = await getProjects();
    if (!projects) {
      actions.setProjects([]);
      actions.setSidebarIsLoadingProjects(false);
      snackActions.error(
        "Error when fetching projects - please check your network or contact an administrator"
      );
    } else {
      actions.setProjects(projects);
      actions.setSidebarIsLoadingProjects(false);
    }
  }),
  addProject: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    // project_author and organisation_id NEED TO BE DELETED but simulation don't work if these data don't exist, to discuss with backend
    let project = {
      project_name: payload.name,
      project_author: "Placeholder",
      organisation_id: "Placeholder",
    };
    const projId = await addProject(project);
    if (projId) {
      actions.setCurrentItem(["projectId", projId]);
      actions.setSidebarOpenItems(projId);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  deleteProject: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const deleteRes = await deleteProject(payload);
    if (deleteRes === "deleted") {
      actions.setModalStep([0]);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  getLocationId: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const res = await getLocationId(payload);
    if (res.err === false) {
      console.log(res);
    }

    actions.setSidebarIsLoadingProjects(false);

    return res;
  }),
  addAOI: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    let aoi = {
      project_id: payload.project_id,
      area_name: payload.area_name,
      area_location: payload.area_location,
      use_latlon: payload.use_latlon,
    };
    const res = await addAOI(aoi);
    if (res.err === false) {
      actions.setCurrentItem(["aoiId", res.message]);
      actions.setSidebarOpenItems(res.message);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  deleteAOI: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const deleteRes = await deleteAOI(payload);
    if (deleteRes === "deleted") {
      actions.setModalStep([0]);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  renameAOI: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const res = await renameAOI({
      area_name: payload.name,
      aoi_id: payload.id,
    });
    if (res.err === false) {
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  addTurbine: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    let turbine = {
      aoi_id: payload.aoiId,
      project_id: payload.projectId,
      turbine_name: payload.turbinename,
      rotor_diameter: payload.rotordiameter,
      hub_height: payload.hubheight,
      rpm: payload.nomrotspeed,
      turbine_location: [payload.xInput, payload.yInput],
    };
    const response = await addTurbine(turbine);
    if (response.err !== true) {
      actions.setCurrentItem(["turbineId", response.message]);
      actions.setSidebarOpenItems(response.message);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
    }
    return response;
  }),
  deleteTurbine: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const deleteRes = await deleteTurbine(payload);
    if (deleteRes === "deleted") {
      actions.setModalStep([0]);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  renameTurbine: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const res = await renameTurbine({
      turbine_name: payload.name,
      wt_id: payload.id,
    });
    if (res.err === false) {
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  addConfiguration: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    let configuration = {
      configuration_name: payload.calcname,
      project_id: payload.projectId,
      aoi_id: payload.aoiId,
      wt_id: payload.turbineId,
      yaw_angle: payload.yawdegree,
      wind_climate: payload.windData,
      preventive_stop:
        payload.withprevstop === "with"
          ? true
          : payload.withprevstop === "without"
          ? false
          : null,
      fixed_yaw: payload.yawtype,
    };
    if (payload.day_ahead) {
      configuration.day_ahead = payload.day_ahead;
    }
    const configId = await addConfiguration(configuration);
    if (configId) {
      actions.setCurrentItem(["calculationId", configId]);
      actions.setSidebarOpenItems(configId);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  // Might get used later if calls go through actions for that
  // addClonedConfiguration: thunk(async(actions, payload) => {
  //   actions.setSidebarIsLoadingProjects(true);
  //   let configuration = {
  //     configuration_name: payload.calcname,
  //     project_id: payload.projectId,
  //     aoi_id: payload.aoiId,
  //     wt_id: payload.turbineId,
  //     yaw_angle: payload.yawdegree,
  //     wind_climate: payload.windData,
  //     preventive_stop:
  //       payload.withprevstop === "with" ? true : payload.withprevstop === "without" ? false : null,
  //     fixed_yaw: payload.yawtype
  //   };
  //   const configId = await addClonedConfiguration(configuration, payload.clonedConfigId);
  //   if (configId) {
  //     // actions.setCurrentItem(["calculationId", configId]);
  //     // actions.setSidebarOpenItems(configId);
  //     // const projects = await getProjects();
  //     // if (!projects) {
  //     //   actions.setProjects([]);
  //     //   actions.setSidebarIsLoadingProjects(false);
  //     // } else {
  //     //   actions.setProjects(projects);
  //     //   actions.setSidebarIsLoadingProjects(false);
  //     // }
  //   }
  // }),
  deleteConfiguration: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const deleteRes = await deleteConfiguration(payload);
    if (deleteRes === "deleted") {
      actions.setModalStep([0]);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  renameConfiguration: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const res = await renameConfiguration({
      configuration_name: payload.name,
      configuration_id: payload.id,
    });
    if (res.err === false) {
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  addRiskZone: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    let riskZone = {
      project_id: payload.projectId,
      aoi_id: payload.aoiId,
      wt_id: payload.turbineId,
      configuration_id: payload.calculationId,
      user_type: payload.usertype,
      total_users: payload.usersperday,
      usage_rate: payload.timesperday,
      usage_time: payload.presenceduration,
      calculation_method: payload.pathType,
      element_name: payload.elementname,
      speed: payload.travelspeed,
      parking_entrance: payload.markerData,
      coordinates: payload.pathData,
      commentarea: payload.commentarea,
      use_latlon: payload.use_latlon,
    };
    const res = await addRiskZone(riskZone);
    if (res.err === false) {
      actions.setCurrentItem(["elementId", res.message]);
      actions.setSidebarOpenItems(res.message);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setStepData(res.message);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  deleteRiskZone: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const deleteRes = await deleteRiskZone(payload);
    if (deleteRes === "deleted") {
      actions.setModalStep([0]);
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
      }
    }
  }),
  renameRiskZone: thunk(async (actions, payload) => {
    actions.setSidebarIsLoadingProjects(true);
    const res = await renameRiskZone({
      element_name: payload.name,
      element_id: payload.id,
    });
    if (res.err === false) {
      const projects = await getProjects();
      if (!projects) {
        actions.setProjects([]);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      } else {
        actions.setProjects(projects);
        actions.setSidebarIsLoadingProjects(false);
        return res;
      }
    } else {
      actions.setSidebarIsLoadingProjects(false);
      return res;
    }
  }),
  windData: null,
  setWindData: action((state, payload) => {
    state.windData = payload;
  }),
  currentPathData: [],
  setCurrentPathData: action((state, payload) => {
    state.currentPathData = payload;
  }),
  currentMarkerData: null,
  setCurrentMarkerData: action((state, payload) => {
    state.currentMarkerData = payload;
  }),
  results: null,
  setResults: action((state, payload) => {
    state.results = payload;
  }),
  // Retains opened projects in local storage
  sidebarOpenItems: persist({ items: [] }, { storage: localStorage }),
  setSidebarOpenItems: action((state, payload) => {
    if (state.sidebarOpenItems.items.includes(payload)) {
      state.sidebarOpenItems.items = state.sidebarOpenItems.items.filter(
        (el) => el !== payload
      );
    } else {
      state.sidebarOpenItems.items.push(payload);
    }
  }),
  existingDrawnItems: { marker: false, polygon: false, polyline: false },
  setExistingDrawnItems: action((state, payload) => {
    state.existingDrawnItems[payload.type] = payload.value;
  }),
};

export default model;
