import React from 'react';
import { useStoreActions } from "easy-peasy";
import { useSnackbar } from "notistack";
import './ReactivationModal.scss';

export default function ReactivationModal(props) {
    const {aoiId, aoiName} = props;
    const { enqueueSnackbar } = useSnackbar();
    const setAoiNeedsReactivationToFalse = useStoreActions(actions => actions.setAoiNeedsReactivationToFalse);
    const setModalStep = useStoreActions(actions => actions.setModalStep);
    const reactivateAOI = useStoreActions(actions => actions.reactivateAOI);
    return (
        <div className="reactivation-modal">
        <div className="reactivation-modal-title">
        You need to reactivate the Area of Interest named "{aoiName}" to continue operations. This action will be invoiced at the end of the current quarter. <br></br> <br></br>Are you willing to continue ?
        </div>
        <div className="reactivation-modal-choice">
            <div
            onClick={() => {
                let responseFromActivation = reactivateAOI(aoiId);
                responseFromActivation
                .then(res => {
                    if (res && res.err === true) {
                    enqueueSnackbar(res.message, {
                        variant: 'error',
                        anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                        }
                    })
                    } else if (res && res.err === false) {
                        setAoiNeedsReactivationToFalse();
                        enqueueSnackbar("Area Of Interest succesfully activated!", {
                        variant: 'success',
                        anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                        }
                    })
                    }
                })
                .catch(err => {
                    enqueueSnackbar(err.message, {
                        variant: 'error',
                        anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                        }
                    })
                })
            }}
            className="reactivation-modal-choice-yes"
            >
            Yes
            </div>
            <div
            onClick={() => {
                setModalStep([null]);
                setAoiNeedsReactivationToFalse();
            }}
            className="reactivation-modal-choice-no"
            >
            No
            </div>
        </div>
        </div>
    )
}
