import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useSnackbar } from "notistack";
import "./Step2.scss";

export default function Step2() {
  const { enqueueSnackbar } = useSnackbar();
  const setModalStep = useStoreActions((actions) => actions.setModalStep);
  const addTurbine = useStoreActions((actions) => actions.addTurbine);
  const stepData = useStoreState((state) => state.stepData);
  const setStepData = useStoreActions((actions) => actions.setStepData);
  const currentItem = useStoreState((state) => state.currentItem);
  const setAoiTurbineCoords = useStoreActions(
    (actions) => actions.setAoiTurbineCoords
  );
  const aoiTurbineCoords = useStoreState((state) => state.aoiTurbineCoords);

  let use_latlon = currentItem.use_latlon;

  if (aoiTurbineCoords[0] < 180 && aoiTurbineCoords[1] < 180) use_latlon = true;

  useEffect(() => {
    // Get coordinates of AOI and injects it into coordinates of wind turbine
    setAoiTurbineCoords(currentItem);
  }, [stepData, currentItem, aoiTurbineCoords, setAoiTurbineCoords]);

  const validationSchema = Yup.object().shape({
    turbinename: Yup.string()
      .required("Turbine Name")
      .test('no-forbidden-chars', 'The turbine name cannot contain /, ? or & characters', (value) => {
        return !/[/?&]/.test(value);
      })
      .typeError("Must be a string"),
    hubheight: Yup.number()
      .required("Hub Height [m]")
      .min(0, "Minimum is 0")
      .max(400, "Maximum is 400")
      .typeError("Must be a number"),
    rotordiameter: Yup.number()
      .required("Rotor Diameter [m]")
      .min(0, "Minimum is 0")
      .max(400, "Maximum is 400")
      .typeError("Must be a number"),
    nomrotspeed: Yup.number()
      .required("Nominal Rotation Speed [rpm]")
      .min(0, "Minimum is 0")
      .max(400, "Maximum is 40")
      .typeError("Must be a number"),

    xInput: use_latlon
      ? Yup.number()
          .required("Latitude")
          .min(-90, "Min is -90")
          .max(90, "Max is 90")
          .typeError("Must be a number")
      : Yup.number()
            .required("Lambert X")
            .min(14697.3, "Minimum is 155823.3")
            .max(297193.15, "Maximum is 297193.15")
            .typeError("Must be a number"),
    yInput: use_latlon
      ? Yup.number()
          .required("Longitude")
          .min(-180, "Min is -180")
          .max(180, "Max is -180")
          .typeError("Must be a number")
      : Yup.number()
          .required("Lambert Y")
          .min(20939.01, "Minimum is 155823")
          .max(246456.18, "Maximum is 297193")
          .typeError("Must be a number")
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      turbinename: (stepData && stepData.turbine_name) || "",
      hubheight: (stepData && stepData.hub_height) || "",
      rotordiameter: (stepData && stepData.rotor_diameter) || "",
      nomrotspeed: (stepData && stepData.rpm) || "",
      xInput:
        (stepData &&
          stepData.turbine_location &&
          stepData.turbine_location[0]) ||
        aoiTurbineCoords[0] ||
        "",
      yInput:
        (stepData &&
          stepData.turbine_location &&
          stepData.turbine_location[1]) ||
        aoiTurbineCoords[1] ||
        "",
    },
    validationSchema,
    onSubmit(data) {
      data.projectId = currentItem.projectId;
      data.aoiId = currentItem.aoiId;

      addTurbine(data)
        .then((res) => {
          if (res.err === true) {
            enqueueSnackbar(res.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
          } else if (res.err === false) {
            setStepData(null);
            setModalStep([3]);
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        });
    },
    enableReinitialize: true,
  });

  return (
    <div id="step-2">
      <div
        className="modal-title"
        style={stepData ? {} : { borderBottom: "solid 1px lightgray" }}
      >
        Wind Turbine Parameters
      </div>
      {stepData && (
        <div className="date-text">
          created:{" "}
          {`${stepData.date.split("T")[0].split("-")[2]}-${
            stepData.date.split("T")[0].split("-")[1]
          }-${stepData.date.split("T")[0].split("-")[0]}`}
        </div>
      )}
      <div className="form-container">
        <form
          style={stepData ? { marginBottom: "10px" } : { marginBottom: "38px" }}
          onSubmit={handleSubmit}
        >
          <TextField
            disabled={!!stepData}
            className="input-field"
            type="text"
            name="turbinename"
            label={
              (touched.turbinename && errors.turbinename) || "Turbine Name"
            }
            error={touched.turbinename && errors.turbinename !== undefined}
            variant="outlined"
            onChange={handleChange}
            value={values.turbinename}
          />
          <TextField
            disabled={!!stepData}
            className="input-field"
            type="text"
            name="hubheight"
            label={(touched.hubheight && errors.hubheight) || "Hub Height [m]"}
            error={touched.hubheight && errors.hubheight !== undefined}
            variant="outlined"
            onChange={handleChange}
            value={values.hubheight}
          />
          <TextField
            disabled={!!stepData}
            className="input-field"
            type="text"
            name="rotordiameter"
            label={
              (touched.rotordiameter && errors.rotordiameter) ||
              "Rotor Diameter [m]"
            }
            error={touched.rotordiameter && errors.rotordiameter !== undefined}
            variant="outlined"
            onChange={handleChange}
            value={values.rotordiameter}
          />
          <TextField
            disabled={!!stepData}
            className="input-field"
            id="rpm-input"
            type="text"
            name="nomrotspeed"
            label={
              (touched.nomrotspeed && errors.nomrotspeed) ||
              "Nominal Rotation Speed [rpm]"
            }
            error={touched.nomrotspeed && errors.nomrotspeed !== undefined}
            variant="outlined"
            onChange={handleChange}
            value={values.nomrotspeed}
          />
          <TextField
            disabled={!!stepData}
            className="input-field-lambert"
            id="x-input"
            type="text"
            name="xInput"
            label={
              touched.xInput && errors.xInput
                ? errors.xInput
                : use_latlon
                ? "Latitude"
                : "Lambert X"
            }
            error={touched.xInput && errors.xInput !== undefined}
            variant="outlined"
            onChange={handleChange}
            value={values.xInput}
          />
          <TextField
            disabled={!!stepData}
            className="input-field-lambert"
            id="y-input"
            type="text"
            name="yInput"
            label={
              touched.yInput && errors.yInput
                ? errors.yInput
                : use_latlon
                ? "Longitude"
                : "Lambert Y"
            }
            error={touched.yInput && errors.yInput !== undefined}
            variant="outlined"
            onChange={handleChange}
            value={values.yInput}
          />
          {stepData ? null : (
            <div className="submit-btn-container">
              <button className="next-button" type="submit">
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
