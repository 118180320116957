import React, { useEffect } from "react";
import L, { Control, FeatureGroup } from "leaflet";
import { useStoreActions, useStoreState } from "easy-peasy";
import "proj4leaflet";
import "leaflet-draw";
import { useSnackbar } from "notistack";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import store from "../../../../../../store";
import windturbine from "../../../../../../assets/images/cross.png";

export default function LeafletMap(props) {
  const setCurrentPathData = useStoreActions(
    (actions) => actions.setCurrentPathData
  );
  const setCurrentMarkerData = useStoreActions(
    (actions) => actions.setCurrentMarkerData
  );
  const setTurbineCoords = useStoreActions(
    (actions) => actions.setTurbineCoords
  );
  const currentItem = useStoreState((state) => state.currentItem);
  const setExistingDrawnItems = useStoreActions(
    (actions) => actions.setExistingDrawnItems
  );
  const { type, pathData, markerData, disableEditing, isClone } = props;
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    setExistingDrawnItems({ type: "marker", value: false });
    setExistingDrawnItems({ type: "polygon", value: false });
    setTurbineCoords({
      projectId: currentItem.projectId,
      aoiId: currentItem.aoiId,
      turbineId: currentItem.turbineId,
    });
    // Rerender map on change between different elements
    let mapBe = L.DomUtil.get("mapcont-be");
    if (mapBe) {
      mapBe.remove();
      mapBe = undefined;
      let mapPre = document.getElementById("map-be");
      if (mapPre) mapPre.innerHTML = '<div id="mapcont-be"></div>';
    }

    let mapWorld = L.DomUtil.get("mapcont-world");
    if (mapWorld) {
      mapWorld.remove();
      mapWorld = undefined;
      let mapPre = document.getElementById("map-world");
      if (mapPre) mapPre.innerHTML = '<div id="mapcont-world"></div>';
    }

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    let drawnItems = new FeatureGroup();

    //Function that returns a serialized layer from coord to Lambert
    const serializeLayer = () => {
      let coo;
      drawnItems.eachLayer((l) => {
        const geoCoordinates = l.toGeoJSON().geometry;
        let latLongCoordinates = geoCoordinates.coordinates;
        if (geoCoordinates.type === "Polygon") {
          latLongCoordinates = latLongCoordinates[0];
        } else if (geoCoordinates.type === "Point") {
          return [latLongCoordinates[1], latLongCoordinates[0]];
        }
        coo = latLongCoordinates.map((c) => {
          return [parseFloat(c[1]), parseFloat(c[0])];
        });
      });
      return coo;
    };

    const access_token =
      "pk.eyJ1Ijoib2xhdXphbm5lIiwiYSI6ImNreDFvZ2FnYjFpYWUyb3VyY2hscmk3cmwifQ.NgvjPTC3bkrlNvtgPjERLw";
    let mapboxLayer = new L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=" +
        access_token,
      { tileSize: 512, zoomOffset: -1 }
    );

    let mymap = new L.map("mapcont-world", {
      maxZoom: 18,
      minZoom: 13,
    });
    mymap.addLayer(mapboxLayer).setView([4.3548, 50.8609], 17);
    if (pathData && !isClone) {
      let existingPathData = pathData.map((data) => {
        return [data[0], data[1]];
      });
      const newLine = new L.Polyline(existingPathData, {
        color: "#3e7aff",
        weight: 5,
        opacity: 0.5,
        smoothFactor: 1,
      });
      newLine.addTo(mymap);
    } else if (pathData && isClone) {
      let existingPathData = pathData.map((data) => {
        return [data[0], data[1]];
      });
      const newLine = new L.Polyline(existingPathData, {
        color: "#3e7aff",
        weight: 5,
        opacity: 0.5,
        smoothFactor: 1,
      });
      newLine.addTo(drawnItems);
      setExistingDrawnItems({ type: "polyline", value: true });
      setCurrentPathData(serializeLayer());
    }
    if (markerData && type === "Parking" && !isClone) {
      let existingMarker = [markerData[0], markerData[1]];
      L.marker(existingMarker).addTo(mymap);
    } else if (markerData && type === "Parking" && isClone) {
      let existingMarker = [markerData[0], markerData[1]];
      L.marker(existingMarker).addTo(drawnItems);
      setExistingDrawnItems({ type: "marker", value: true });
      setCurrentMarkerData(markerData);
    }
    const viewCoords = store.getState().turbineCoords;
    let center = {
      lng: parseFloat(viewCoords[1]),
      lat: parseFloat(viewCoords[0]),
    };
    let bounds = [
      [center.lat - 0.00012, center.lng - 0.00021],
      [center.lat + 0.00015, center.lng + 0.00021],
    ];
    L.imageOverlay(windturbine, bounds).addTo(mymap);
    L.imageOverlay(windturbine, bounds).bringToFront();

    mymap.addLayer(drawnItems);
    let drawControl = new Control.Draw({
      draw: {
        circle: false,
        circlemarker: false,
        rectangle: false,
        polygon:
          !disableEditing &&
          !isClone &&
          (type === "Fixed" || type === "Average" || type === "Parking"),
        polyline: !disableEditing && !isClone && type === "Path",
        marker: !disableEditing && !isClone && type === "Parking",
      },
      edit: isClone
        ? { featureGroup: drawnItems, edit: type !== "Parking" }
        : false,
    });
    mymap.addControl(drawControl);

    mymap.on(L.Draw.Event.CREATED, (e) => {
      let layerType = e.layerType;
      mymap.removeControl(drawControl);
      if (layerType === "marker") {
        drawControl = new Control.Draw({
          draw: {
            circle: false,
            circlemarker: false,
            rectangle: false,
            polygon:
              !disableEditing &&
              (type === "Fixed" || type === "Average" || type === "Parking") &&
              !store.getState().existingDrawnItems.polygon,
            polyline: false,
            marker: false,
          },
          edit: {
            featureGroup: drawnItems,
            edit: false,
          },
        });
        setExistingDrawnItems({ type: "marker", value: true });
        drawnItems.addLayer(e.layer);
        let marker = [e.layer._latlng.lat, e.layer._latlng.lng];
        setCurrentMarkerData([parseFloat(marker[0]), parseFloat(marker[1])]);
      } else if (layerType === "polygon") {
          let areaSquare = L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]);
          if ((type === "Fixed" || type === "Parking") && areaSquare < 50) {
            enqueueSnackbar('The risk area you will be performing your analysis on is smaller than 50m². For such small risk area, TRiceR advises to use the "average" calculation method in order to get actionable results and readable graphs', {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
          }

        drawControl = new Control.Draw({
          draw: {
            circle: false,
            circlemarker: false,
            rectangle: false,
            polygon: false,
            polyline: false,
            marker:
              !disableEditing &&
              type === "Parking" &&
              !store.getState().existingDrawnItems.marker,
          },
          edit: {
            featureGroup: drawnItems,
            edit: type !== "Parking",
          },
        });
        setExistingDrawnItems({ type: "polygon", value: true });
        drawnItems.addLayer(e.layer);
        setCurrentPathData(serializeLayer());
      } else if (layerType === "polyline") {
        drawControl = new Control.Draw({
          draw: {
            circle: false,
            circlemarker: false,
            rectangle: false,
            polygon: false,
            polyline: false,
            marker: false,
          },
          edit: { featureGroup: drawnItems },
        });
        setExistingDrawnItems({ type: "polyline", value: true });
        drawnItems.addLayer(e.layer);
        setCurrentPathData(serializeLayer());
      }
      mymap.addControl(drawControl);
    });

    // Edit works with Path, works with Area, doesn't work with parking markers for now

    mymap.on(L.Draw.Event.EDITED, (e) => {
      let layer = e.layers._layers[Object.keys(e.layers._layers)[0]];
      let layerType = e.layerType;
      if (layerType === "marker") {
        drawnItems.clearLayers();
        drawnItems.addLayer(layer);
        let marker = [layer._latlng.lat, layer._latlng.lng];

        setCurrentMarkerData([parseFloat(marker[0]), parseFloat(marker[1])]);
      } else {
          // Check for polygon area
          if ((type === "Fixed") && typeof layer.getLatLngs === 'function') { // check if polygon && Fixed
            let areaSquare = L.GeometryUtil.geodesicArea(
              layer.getLatLngs()[0]
            );
            if (areaSquare < 50) {
              enqueueSnackbar('The risk area you will be performing your analysis on is smaller than 50m². For such small risk area, TRiceR advises to use the "average" calculation method in order to get actionable results and readable graphs', {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              });
            }
          }
        drawnItems.clearLayers();
        drawnItems.addLayer(layer);
        setCurrentPathData(serializeLayer());
      }
    });

    // Delete works with Path,
    mymap.on(L.Draw.Event.DELETED, (e) => {
      // let deletedLayers = e.layers;
      mymap.removeControl(drawControl);
      drawnItems.clearLayers();
      setCurrentMarkerData(null);
      setCurrentPathData([]);
      setExistingDrawnItems({ type: "marker", value: false });
      setExistingDrawnItems({ type: "polygon", value: false });
      setExistingDrawnItems({ type: "polyline", value: false });
      drawControl = new Control.Draw({
        draw: {
          circle: false,
          circlemarker: false,
          rectangle: false,
          polygon:
            !disableEditing &&
            (type === "Fixed" || type === "Average" || type === "Parking") &&
            !store.getState().existingDrawnItems.polygon,
          polyline:
            !disableEditing &&
            type === "Path" &&
            !store.getState().existingDrawnItems.polyline,
          marker:
            !disableEditing &&
            type === "Parking" &&
            !store.getState().existingDrawnItems.marker,
        },
        edit: false,
      });
      mymap.addControl(drawControl);
    });

    mymap.setView(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData, isClone]);

  return <div id="mapcont-world"></div>;
}
