import React, {useEffect} from "react";
import "./DeleteModal.scss";
import { useStoreActions } from "easy-peasy";

export default function DeleteModal(props) {
  const { name, type, id, setModalState } = props;
  const deleteProject = useStoreActions(actions => actions.deleteProject);
  const deleteAOI = useStoreActions(actions => actions.deleteAOI);
  const deleteTurbine = useStoreActions(actions => actions.deleteTurbine);
  const deleteConfiguration = useStoreActions(actions => actions.deleteConfiguration);
  const deleteRiskZone = useStoreActions(actions => actions.deleteRiskZone);


  const deleteItem = () => {
    switch (type) {
      case "project":
        deleteProject(id);
        break;
      case "aoi":
        deleteAOI(id);
        break;
      case "turbine":
        deleteTurbine(id);
        break;
      case "configuration":
        deleteConfiguration(id);
        break;
      case "risk_zone":
        deleteRiskZone(id);
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    //function used to close the modal when clicking outside
   const addCloseContextFunc = (e) => {
       if(!e.target.closest(".delete-modal")) {
           setModalState(false);
       }
   };
   setTimeout(() => {
       window.addEventListener("click", addCloseContextFunc, false);
   }, 0)
   
   return () => {
       window.removeEventListener("click", addCloseContextFunc, false);
   }
}, [setModalState])

  return (
    <div className="delete-modal">
      <div className="delete-modal-title">
        Are you sure you want to delete "{name}" and all of its elements ?
      </div>
      <div className="delete-modal-choice">
        <div
          onClick={() => {
            setModalState(false);
            deleteItem();
          }}
          className="delete-modal-choice-yes"
        >
          Yes
        </div>
        <div
          onClick={() => {
            setModalState(false);
          }}
          className="delete-modal-choice-no"
        >
          No
        </div>
      </div>
    </div>
  );
}
