import React, { useEffect } from "react";
import L, { FeatureGroup } from "leaflet";
import "proj4leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import windturbine from "../../../../../../assets/images/cross.png";

export default function LeafletMap(props) {
  const { coords, lambert } = props;

  useEffect(() => {
    if (lambert) return;
    let mapBe = L.DomUtil.get("mapcont-be");
    if (mapBe) {
      mapBe.remove();
      mapBe = undefined;
      let mapPre = document.getElementById("map-be");
      if (mapPre) mapPre.innerHTML = '<div id="mapcont-be"></div>';
    }
    let mapWorld = L.DomUtil.get("mapcont-world");
    if (mapWorld) {
      mapWorld.remove();
      mapWorld = undefined;
      let mapPre = document.getElementById("map-world");
      if (mapPre) mapPre.innerHTML = '<div id="mapcont-world"></div>';
    }
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    const access_token =
      "pk.eyJ1Ijoib2xhdXphbm5lIiwiYSI6ImNreDFvZ2FnYjFpYWUyb3VyY2hscmk3cmwifQ.NgvjPTC3bkrlNvtgPjERLw";
    let mapboxLayer = new L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=" +
        access_token,
      { tileSize: 512, zoomOffset: -1 }
    );
    let drawnItems = new FeatureGroup();
    let mymap = new L.map("mapcont-world", {
      maxZoom: 18,
      minZoom: 13,
    });
    mymap.addLayer(mapboxLayer).setView([4.3548, 50.8609], 17);
    const center = {
      lng: parseFloat(coords[1]) || 0,
      lat: parseFloat(coords[0]) || 0,
    };
    let bounds = [
      [center.lat - 0.00012, center.lng - 0.00021],
      [center.lat + 0.00015, center.lng + 0.00021],
    ];
    L.imageOverlay(windturbine, bounds).addTo(mymap);
    L.imageOverlay(windturbine, bounds).bringToFront();
    // adding circle
    L.circle(center, {
      color: "#67A4FC",
      fillColor: "#67A4FC",
      fillOpacity: 0.2,
      radius: 100,
    }).addTo(drawnItems);
    mymap.addLayer(drawnItems);
    mymap.setView(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  return <div id="mapcont-world" key="mapcont-world"></div>;
}
