import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import UserLogout from "./UserLogout/UserLogout";
import UserName from "./UserName/UserName";
import UserNotification from "./UserNotification/UserNotification";
import UserSupport from "./UserSupport/UserSupport";
import ReactivationModal from "./ReactivationModal/ReactivationModal";
import Step1 from "./Steps/Step1/Step1";
import Step1b from "./Steps/Step1b/Step1b";
import Step2 from "./Steps/Step2/Step2";
import Step3 from "./Steps/Step3/Step3";
import Step3b from "./Steps/Step3b/Step3b";
import Step4 from "./Steps/Step4/Step4";
import Step5 from "./Steps/Step5/Step5";
import WhatsNew from "./WhatsNew/WhatsNew";
import "./ModalsArea.scss";

// With every update, change the version and the isShowable variable to true if you need to anounce new features

export default function ModalsArea() {
  const appVersion = "2.1.5";
  // CHANGE isShowable TO FALSE IF PATCH NOTE IS MINOR AND NO NEW FEATURES and the lastNewFeaturesVersion to the latest version that contained
  // new features so the message keeps being displayed for that version when new minor versions come out
  // isShowable should be true only on versions with new features
  const lastNewFeaturesVersion = "2.1.3";
  const isShowable = false;
  // END of vars responsible for version status messages

  const modalStep = useStoreState((state) => state.modalStep);
  const { needsReactivation, aoiId, aoiName } = useStoreState(
    (state) => state.aoiNeedsReactivation
  );
  const [showNews, setShowNews] = useState(false);
  useEffect(() => {}, [modalStep]);
  // This effect checks for the need to reactivate an AOI
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsReactivation, aoiId, aoiName]);

  // Used to check if user has seen patch notes at least once, if not it pops up the patch notes
  // isShowable should be true only on versions with new features
  useEffect(() => {
    if (isShowable) {
      let hasAcknowledgedPatchNotes = localStorage.getItem(
        "patchNotesAcknowledged"
      );
      let lastAcknowledgedVersion = localStorage.getItem(
        "lastAcknowledgedVersion"
      );
      if (
        hasAcknowledgedPatchNotes === null ||
        lastAcknowledgedVersion === null
      ) {
        localStorage.setItem("patchNotesAcknowledged", false);
        localStorage.setItem("lastAcknowledgedVersion", appVersion);
        setShowNews(true);
      } else if (
        hasAcknowledgedPatchNotes === "false" ||
        lastAcknowledgedVersion !== appVersion
      ) {
        setShowNews(true);
      }
    }
  }, [isShowable]);
  return (
    <div className="modals-area">
      {showNews ? (
        <WhatsNew
          toggleNews={setShowNews}
          appVersion={appVersion}
          lastNewFeaturesVersion={lastNewFeaturesVersion}
        />
      ) : null}
      <div className="modals-area-top">
        <UserName />
        <UserNotification toggleNews={setShowNews} />
        <UserSupport />
        <UserLogout />
      </div>
      {needsReactivation === false ? (
        modalStep[0] === 0 ? (
          <div id="modals-area-placeholder-div"></div>
        ) : modalStep[0] === 1 ? (
          <Step1 />
        ) : modalStep[0] === "1b" ? (
          <Step1b />
        ) : modalStep[0] === 2 ? (
          <Step2 />
        ) : modalStep[0] === 3 ? (
          <Step3
            isClone={modalStep[1] || false}
            cloneId={modalStep[2] || false}
            cloneName={modalStep[3] || false}
          />
        ) : modalStep[0] === "3b" ? (
          <Step3b />
        ) : modalStep[0] === 4 ? (
          <Step4
            stepType={modalStep[1] || false}
            cloneData={modalStep[2] || false}
            targetConfig={modalStep[3] || false}
            isClone={modalStep[4] || false}
          />
        ) : modalStep[0] === 5 ? (
          <Step5 />
        ) : (
          <div id="modals-area-placeholder-div"></div>
        )
      ) : (
        <ReactivationModal aoiId={aoiId} aoiName={aoiName} />
      )}
      <div className="modals-area-bottom">
        <div id="copyright-tag">&copy; 2022 TRACTEBEL ENGINEERING S.A.</div>
        <div id="version-name">v {appVersion}</div>
      </div>
    </div>
  );
}
