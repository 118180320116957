import React from "react";
import SidePanel from "./SidePanel/SidePanel";
import ModalsArea from "./ModalsArea/ModalsArea";
import "./Main.scss";

export default function Main() {
  return (
    <div className="main">
      <SidePanel />
      <ModalsArea />
    </div>
  );
}
