// Prod API
// export const apiUrl = "https://fgm70qg42f.execute-api.eu-west-1.amazonaws.com/prod/";
// No Prod API
export const apiUrl = "https://icefall.tricer-noprod.aws.tractebel-engie.com/";
export const pdfUrl = "https://genpdf.tricer-noprod.aws.tractebel-engie.com/";
// Demo API
// export const apiUrl = "https://1c6gtt22c1.execute-api.eu-west-1.amazonaws.com/demo/";

export const mapbox_access_token =
  "pk.eyJ1Ijoib2xhdXphbm5lIiwiYSI6ImNreDFvZ2FnYjFpYWUyb3VyY2hscmk3cmwifQ.NgvjPTC3bkrlNvtgPjERLw";
