import React, {useState, useEffect} from 'react'

export default function TimerComponent() {
    const [currentSeconds, setSeconds] = useState(0);
    const [currentMinutes, setMinutes] = useState(0);
    useEffect(() => {
        let id;
        if(currentSeconds === 59) {
           
            id = setInterval(() => {
                setSeconds(0);
                setMinutes(currentMinutes + 1);
            }, 1000);
        } else {
            id = setInterval(() => {
                setSeconds(currentSeconds + 1);
            }, 1000);
        }
        
        return () => {
            clearInterval(id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSeconds])
    return (
        <span>
           {currentMinutes > 0 ? currentMinutes + " min " : ""} {currentSeconds + " s"}
        </span>
    )
}
