import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { v4 as uuid } from "uuid";
import "./WindTable.scss";

export default function WindTable() {
  const windData = useStoreState((state) => state.windData);
  const stepData = useStoreState((state) => state.stepData);
  const directions = [
    "N",
    "NNE",
    "ENE",
    "E",
    "ESE",
    "SSE",
    "S",
    "SSW",
    "WSW",
    "W",
    "WNW",
    "NNW",
  ];
  useEffect(() => {}, [windData, stepData]);

  if (windData) {
    return (
      <div id="table-container">
        <table id="main-table">
          <thead>
            <tr key="header-row">
              <th key="sector">Sector</th>
              <th key="ams">A [m/s]</th>
              <th key="k">k [-]</th>
              <th key="freq">Freq [-]</th>
              <th key="shearexp">Shear exponent [-]</th>
            </tr>
          </thead>
          <tbody>
            {windData.map((value, index) => (
              <tr key={"row" + index}>
                <td key={uuid()}>{directions[index]}</td>
                {windData[index].map((value) =>
                  typeof value === "string" ? (
                    <td key={uuid()}>
                      {parseFloat(value.replace(/,/g, ".")).toFixed(4)}
                    </td>
                  ) : (
                    <td key={uuid()}>{parseFloat(value).toFixed(4)}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else if (stepData) {
    return (
      <div id="table-container">
        <table id="main-table">
          <thead>
            <tr key="header-row">
              <th key="sector">Sector</th>
              <th key="ams">A [m/s]</th>
              <th key="k">k [-]</th>
              <th key="freq">Freq [-]</th>
              <th key="shearexp">Shear exponent [-]</th>
            </tr>
          </thead>
          <tbody>
            {stepData.wind_climate.map((value, index) => (
              <tr key={"row" + index}>
                <td key={uuid()}>{directions[index]}</td>
                {stepData.wind_climate[index].map((value) =>
                  typeof value === "string" ? (
                    <td key={uuid()}>
                      {parseFloat(value.replace(/,/g, ".")).toFixed(4)}
                    </td>
                  ) : (
                    <td key={uuid()}>{parseFloat(value).toFixed(4)}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
}
