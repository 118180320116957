export default function transformDataIntoCallBody(projects, data, filePath) {
  let windData = projects
    .filter((project) => project.project_id === data.project_id)[0]
    .areas_of_interest.filter((aoi) => aoi.aoi_id === data.aoi_id)[0]
    .wind_turbines.filter((turbine) => turbine.wt_id === data.wt_id)[0]
    .configurations.filter(
      (config) => config.configuration_id === data.configuration_id
    )[0].wind_climate;

  let use_latlon = projects
    .filter((project) => project.project_id === data.project_id)[0]
    .areas_of_interest.filter(
      (aoi) => aoi.aoi_id === data.aoi_id
    )[0].use_latlon;
  let parsedWindData = windData.map((el) => {
    return el.map((elt) => {
      if (typeof elt === "string") {
        let newEl = elt.replace(/,/g, ".");
        return parseFloat(newEl);
      } else {
        return parseFloat(elt);
      }
    });
  });

  let turbine = projects
    .filter((project) => project.project_id === data.project_id)[0]
    .areas_of_interest.filter((aoi) => aoi.aoi_id === data.aoi_id)[0]
    .wind_turbines.filter((turbine) => turbine.wt_id === data.wt_id)[0];

  let configuration = projects
    .filter((project) => project.project_id === data.project_id)[0]
    .areas_of_interest.filter((aoi) => aoi.aoi_id === data.aoi_id)[0]
    .wind_turbines.filter((turbine) => turbine.wt_id === data.wt_id)[0]
    .configurations.filter(
      (config) => config.configuration_id === data.configuration_id
    )[0];

  if (data.calculation_method === "Path") {
    let constuctedObj = {
      map_limit: 150,
      filename_prefix: filePath,
      path: {
        user_type: data.user_type,
        usage_rate: parseFloat(data.usage_rate),
        total_users: parseFloat(data.total_users),
        plot_name: data.element_name,
        coordinates: data.coordinates,
        speed: parseFloat(data.speed),
        commentarea: data.commentarea,
        use_latlon: data.use_latlon,
      },
      fixed_yaw: configuration.fixed_yaw === "fixed",
      yaw_angle: parseFloat(configuration.yaw_angle),
      preventive_stop: configuration.preventive_stop,
      hub_height: parseFloat(turbine.hub_height),
      rotor_diameter: parseFloat(turbine.rotor_diameter),
      rpm: parseFloat(turbine.rpm),
      turbine_location: [
        parseFloat(turbine.turbine_location[0]),
        parseFloat(turbine.turbine_location[1]),
      ],
      wind_climate: parsedWindData,
    };
    if (configuration.day_ahead) {
      constuctedObj.day_ahead = configuration.day_ahead;
    }
    if (use_latlon) constuctedObj.use_latlon = use_latlon;
    return constuctedObj;
  } else if (data.calculation_method === "Parking") {
    let constuctedObj = {
      map_limit: 150,
      filename_prefix: filePath,
      area: {
        calculation_method: "Parking",
        user_type: data.user_type,
        usage_rate: parseFloat(data.usage_rate),
        total_users: parseFloat(data.total_users),
        plot_name: data.element_name,
        coordinates: data.coordinates,
        speed: parseFloat(data.speed),
        parking_entrance: data.parking_entrance,
        commentarea: data.commentarea,
        use_latlon: data.use_latlon,
      },
      fixed_yaw: configuration.fixed_yaw === "fixed",
      yaw_angle: parseFloat(configuration.yaw_angle),
      preventive_stop: configuration.preventive_stop,
      hub_height: parseFloat(turbine.hub_height),
      rotor_diameter: parseFloat(turbine.rotor_diameter),
      rpm: parseFloat(turbine.rpm),
      turbine_location: [
        parseFloat(turbine.turbine_location[0]),
        parseFloat(turbine.turbine_location[1]),
      ],
      wind_climate: parsedWindData,
    };
    if (configuration.day_ahead) {
      constuctedObj.day_ahead = configuration.day_ahead;
    }
    if (use_latlon) constuctedObj.use_latlon = use_latlon;
    return constuctedObj;
  } else if (
    data.calculation_method === "Average" ||
    data.calculation_method === "Fixed"
  ) {
    let constuctedObj = {
      map_limit: 150,
      filename_prefix: filePath,
      area: {
        calculation_method:
          data.calculation_method === "Average" ? "Average" : "Fixed",
        user_type: data.user_type,
        usage_rate: parseFloat(data.usage_rate),
        total_users: parseFloat(data.total_users),
        plot_name: data.element_name,
        coordinates: data.coordinates,
        usage_time: parseFloat(data.usage_time),
        commentarea: data.commentarea,
        use_latlon: data.use_latlon,
      },
      fixed_yaw: configuration.fixed_yaw === "fixed",
      yaw_angle: parseFloat(configuration.yaw_angle),
      preventive_stop: configuration.preventive_stop,
      hub_height: parseFloat(turbine.hub_height),
      rotor_diameter: parseFloat(turbine.rotor_diameter),
      rpm: parseFloat(turbine.rpm),
      turbine_location: [
        parseFloat(turbine.turbine_location[0]),
        parseFloat(turbine.turbine_location[1]),
      ],
      wind_climate: parsedWindData,
    };
    if (configuration.day_ahead) {
      constuctedObj.day_ahead = configuration.day_ahead;
    }
    if (use_latlon) constuctedObj.use_latlon = use_latlon;
    return constuctedObj;
  }
}
