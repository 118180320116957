import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Amplify } from "aws-amplify";
import BelgiumMap from "./Map/BelgiumMap";
import WorldMap from "./Map/WorldMap";
import Results from "../../Results/Results";
import cloudIcon from "../../../../../assets/icons/cloud.svg";
import store from "../../../../../store";
import { useSnackbar } from "notistack";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-pulse-sync.scss";
import "./Step4.scss";

export default function Step4(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { stepType, cloneData, isClone } = props;
  // On toggle, changes between the map and results view
  const [showResults, setShowResults] = useState(false);
  const [isSocietal, setSocietal] = useState(true);
  // Stores list of Results from S3 storage
  const [results, setPastResults] = useState(null);
  // The next 2 variables are used to decide what view to show when switching riskzones in the sidebar
  const isResultsScreenOn = useStoreState((state) => state.isResultsScreenOn);
  const setResultScreenStatus = useStoreActions(
    (actions) => actions.setResultScreenStatus
  );
  const setModalStep = useStoreActions((actions) => actions.setModalStep);
  const stepData = useStoreState((state) => state.stepData);
  const addRiskZone = useStoreActions((actions) => actions.addRiskZone);
  const setResults = useStoreActions((actions) => actions.setResults);
  const currentPathData = useStoreState((state) => state.currentPathData);
  const currentMarkerData = useStoreState((state) => state.currentMarkerData);
  const setCurrentPathData = useStoreActions(
    (actions) => actions.setCurrentPathData
  );
  const setCurrentMarkerData = useStoreActions(
    (actions) => actions.setCurrentMarkerData
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  let cardTitle;
  let stepName = stepType || (stepData && stepData.calculation_method);

  useEffect(() => {
    if (isClone) {
      setShowResults(false);
      toggleResults(false);
    }
    if (stepData) {
      if (isResultsScreenOn) {
        toggleResults(true);
      }
    } else {
      toggleResults(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData, isClone, cloneData, isSocietal]);

  if (stepData) {
    switch (stepName) {
      case "Average":
        cardTitle = "Area (Average) Data";
        break;
      case "Fixed":
        cardTitle = "Area (Fixed) Data";
        break;
      case "Parking":
        cardTitle = "Area (Parking) Data";
        break;
      case "Path":
        cardTitle = "Path Data";
        break;
      default:
        cardTitle = undefined;
    }
  } else {
    switch (stepName) {
      case "Average":
        cardTitle = "Create an Area (Average)";
        break;
      case "Fixed":
        cardTitle = "Create an Area (Fixed)";
        break;
      case "Parking":
        cardTitle = "Create an Area (Parking)";
        break;
      case "Path":
        cardTitle = "Create a Path";
        break;
      default:
        cardTitle = undefined;
    }
  }

  const toggleResults = (val) => {
    if (val === true) {
      Amplify.Storage.list(stepData.element_id, {
        level: "protected",
        identityId: stepData.user_id,
      })
        .then((res) => {
          setPastResults(res);
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        });
      setShowResults(true);
      setResultScreenStatus(true);
    } else {
      setPastResults(null);
      setShowResults(false);
      setResultScreenStatus(false);
    }
  };

  const toggleSubmitButton = (val) => {
    let button = document.querySelector(".next-button");
    if (val === false && button !== null) {
      button.setAttribute("disabled", true);
    } else if (button !== null) {
      button.removeAttribute("disabled");
    }
  };

  const validationShape = {
    elementname: Yup.string()
      .required("Name of the riskzone")
      .test('no-forbidden-chars', 'The riskzone name cannot contain /, ? or & characters', (value) => {
        return !/[/?&]/.test(value);
      })
      .typeError("Must be a string"),
    usertype: Yup.string()
      .oneOf(["Vulnerable", "Public", "Employee", "Industry"])
      .required("Provide a user type"),
    usersperday: Yup.number()
      .required("N° of total users per day")
      .min(0.001, "Must be superior to 0")
      .typeError("Must be a number"),
    timesperday: Yup.number()
      .required(
        stepName === "Path"
          ? "N° of times the path is used by the same person per day"
          : "N° of times the area is used by the same person per day"
      )
      .min(0.001, "Must be superior to 0")
      .typeError("Must be a number"),
    commentarea: Yup.string().typeError("Must be a string"),
  };

  if (stepName === "Path" || stepName === "Parking") {
    validationShape.travelspeed = Yup.number()
      .required("Travel speed [km/h]")
      .min(0.001, "Must be superior to 0")
      .typeError("Must be a number");
  } else {
    validationShape.presenceduration = Yup.number()
      .required("Enter how long the user is present each time [min]")
      .min(0.001, "Must be superior to 0")
      .typeError("Must be a number");
  }

  const validationSchema = Yup.object().shape(validationShape);

  const current_aoi = store
    .getState()
    .projects.filter(
      (p) =>
        p.areas_of_interest.filter(
          (aoi) => aoi.aoi_id === store.getState().currentItem.aoiId
        )[0]
    )[0]
    .areas_of_interest.filter(
      (aoi) => aoi.aoi_id === store.getState().currentItem.aoiId
    )[0];

  const use_latlon = current_aoi && current_aoi.use_latlon;

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      elementname:
        (stepData && stepData.element_name) ||
        (isClone && cloneData.element_name) ||
        "",
      usertype:
        (stepData && stepData.user_type) ||
        (isClone && cloneData.user_type) ||
        "",
      usersperday:
        (stepData && stepData.total_users) ||
        (isClone && cloneData.total_users) ||
        "",
      timesperday:
        (stepData && stepData.usage_rate) ||
        (isClone && cloneData.usage_rate) ||
        "",
      travelspeed:
        (stepData && stepData.speed) || (isClone && cloneData.speed) || "",
      presenceduration:
        (stepData && stepData.usage_time) ||
        (isClone && cloneData.usage_time) ||
        "",
      commentarea:
        (stepData && stepData.commentarea) ||
        (isClone && cloneData.commentarea) ||
        "",
    },
    validationSchema,
    onSubmit(data) {
      // préparer ici le message d'erreur en cas d'oubli de zone ou marker
      if (currentPathData.length <= 0) {
        setIsSubmitting(false);
        enqueueSnackbar("Missing risk zone", {
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } else if (stepType === "Parking" && currentMarkerData === null) {
        setIsSubmitting(false);
        enqueueSnackbar("Missing parking entrance", {
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } else if (
        currentPathData.length > 0 &&
        ((stepType === "Parking" && currentMarkerData !== null) ||
          stepType !== "Parking")
      ) {
        if (stepName === "Path" || stepName === "Parking") {
          delete data.presenceduration;
        } else {
          delete data.travelspeed;
        }
        setIsSubmitting(true);
        toggleSubmitButton(false);
        data.projectId = store.getState().currentItem.projectId;
        data.aoiId = store.getState().currentItem.aoiId;
        data.turbineId = store.getState().currentItem.turbineId;
        data.calculationId = store.getState().currentItem.calculationId;
        data.pathType = stepType;
        data.pathData = currentPathData;
        data.markerData = currentMarkerData;
        data.use_latlon =
          use_latlon ||
          (store.getState().stepData && store.getState().stepData.use_latlon);
        setResults(null);
        addRiskZone(data)
          .then((res) => {
            if (res && res.err === true) {
              setIsSubmitting(false);
              toggleSubmitButton(true);
              enqueueSnackbar(res.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              });
            } else if (res && res.err === false) {
              setIsSubmitting(false);
              toggleSubmitButton(true);
              setCurrentPathData([]);
              setCurrentMarkerData(null);
              setModalStep([5]);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsSubmitting(false);
            toggleSubmitButton(true);
            enqueueSnackbar(err.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
          });
      }
    },
    enableReinitialize: true,
  });

  return (
    <div
      className="step-4-container"
      style={
        stepData
          ? { gridTemplateRows: "65px auto" }
          : { gridTemplateRows: "auto" }
      }
    >
      {isSubmitting ? (
        <div id="submit-loader-div">
          <Loader type="ball-pulse-sync" active />
        </div>
      ) : null}
      {stepData !== null ? (
        <div id="toggle-cont-grid">
          <div id="tog-cont">
            <div
              onClick={() => toggleResults(!isResultsScreenOn)}
              className={showResults ? "on" : "off"}
              id="toggle-existing-results"
            >
              <div id="status-text-off">OFF</div>
              <div id="status-text-on">ON</div>
              <div id="slider"></div>
            </div>
            <div id="toggle-text">Show results</div>
          </div>
          {showResults ? (
            <div id="socind-tog-cont">
              <div
                onClick={() => setSocietal((prev) => !prev)}
                className={!isSocietal ? "on" : "off"}
                id="toggle-societal-individual"
              >
                <div id="status-text-ind">SOCIETAL</div>
                <div id="status-text-soc">INDIVIDUAL</div>
                <div id="slider-socind"></div>
              </div>
              <div id="socind-text">Societal/Individual</div>
            </div>
          ) : null}
        </div>
      ) : null}
      {showResults && results ? (
        <div
          style={
            results.length === 1
              ? {
                  width: "500px",
                  height: "200px",
                  alignSelf: "center",
                  background: "white",
                }
              : {}
          }
          className="results-container"
        >
          {results.results.length > 1 &&
            results.results.map((res) => (
              <Results
                isSocietal={isSocietal}
                key={res.key}
                fileId={res.key}
                userId={stepData.user_id}
              />
            ))}
        </div>
      ) : (
        <div
          id="step-4"
          style={
            stepData
              ? {
                  gridTemplateRows: "30px 22px auto",
                  height: "calc(90vh - 65px)",
                }
              : { gridTemplateRows: "46px auto", height: "90vh" }
          }
        >
          <div
            className="modal-title"
            style={stepData ? {} : { borderBottom: "solid 1px lightgray" }}
          >
            {cardTitle}
          </div>
          {stepData && (
            <div className="date-text">
              created:{" "}
              {`${stepData.date.split("T")[0].split("-")[2]}-${
                stepData.date.split("T")[0].split("-")[1]
              }-${stepData.date.split("T")[0].split("-")[0]}`}
            </div>
          )}
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div id="map">
                {use_latlon ? (
                  <div id="map-world" key="map-world">
                    <WorldMap
                      isClone={isClone}
                      type={stepName}
                      pathData={
                        (stepData && stepData.coordinates) ||
                        (isClone && cloneData.coordinates)
                      }
                      markerData={
                        (stepData && stepData.parking_entrance) ||
                        (isClone && cloneData.parking_entrance)
                      }
                      disableEditing={stepData ? true : false}
                    />
                  </div>
                ) : (
                  <div id="map-be" key="map-be">
                    <BelgiumMap
                      isClone={isClone}
                      type={stepName}
                      pathData={
                        (stepData && stepData.coordinates) ||
                        (isClone && cloneData.coordinates)
                      }
                      markerData={
                        (stepData && stepData.parking_entrance) ||
                        (isClone && cloneData.parking_entrance)
                      }
                      disableEditing={stepData ? true : false}
                    />
                  </div>
                )}
              </div>
              <TextField
                disabled={!!stepData}
                className="input-field"
                type="text"
                name="elementname"
                label={
                  (touched.elementname && errors.elementname) ||
                  "Name of the riskzone"
                }
                error={touched.elementname && !!errors.elementname}
                variant="outlined"
                onChange={handleChange}
                value={values.elementname}
              />
              <select
                error={touched.usertype && errors.usertype}
                disabled={!!stepData}
                name="usertype"
                value={values.usertype}
                onChange={handleChange}
              >
                <option value="">Select user type</option>
                <option
                  value="Vulnerable"
                  title="Vulnerable is for vulnerable users such as the elderly or children"
                >
                  Vulnerable
                </option>
                <option
                  value="Public"
                  title="Public is for uninformed public persons, for example a pedestrian walking near the turbine."
                >
                  Public
                </option>
                <option
                  value="Employee"
                  title="Employee is trained staff of the wind turbine operator only."
                >
                  Employee
                </option>
                <option
                  value="Industry"
                  title="Industry is informed staff that can be instructed to follow a safety plan, e.g. on an industrial site."
                >
                  Industry
                </option>
              </select>
              <TextField
                disabled={!!stepData}
                className="input-field"
                type="text"
                name="usersperday"
                label={
                  (touched.usersperday && errors.usersperday) ||
                  "N° of total users per day"
                }
                error={touched.usersperday && !!errors.usersperday}
                variant="outlined"
                onChange={handleChange}
                value={values.usersperday}
              />
              <TextField
                disabled={!!stepData}
                className="input-field"
                type="text"
                name="timesperday"
                label={
                  (touched.timesperday && errors.timesperday) ||
                  (stepName === "Path"
                    ? "N° of times the path is used by the same person per day"
                    : "N° of times the area is used by the same person per day")
                }
                error={touched.timesperday && !!errors.timesperday}
                variant="outlined"
                onChange={handleChange}
                value={values.timesperday}
              />
              {stepName === "Path" || stepName === "Parking" ? (
                <TextField
                  disabled={!!stepData}
                  className="input-field"
                  type="text"
                  name="travelspeed"
                  label={
                    (touched.travelspeed && errors.travelspeed) ||
                    "Travel speed [km/h]"
                  }
                  error={touched.travelspeed && !!errors.travelspeed}
                  variant="outlined"
                  onChange={handleChange}
                  value={values.travelspeed}
                />
              ) : (
                <TextField
                  disabled={!!stepData}
                  className="input-field"
                  type="text"
                  name="presenceduration"
                  label={
                    (touched.presenceduration && errors.presenceduration) ||
                    "Enter how long the user is present each time [min]"
                  }
                  error={touched.presenceduration && !!errors.presenceduration}
                  variant="outlined"
                  onChange={handleChange}
                  value={values.presenceduration}
                />
              )}
              <TextField
                disabled={!!stepData}
                className="input-field"
                type="text"
                multiline
                maxRows={4}
                name="commentarea"
                label={
                  (touched.commentarea && errors.commentarea) || "Comments"
                }
                error={touched.commentarea && !!errors.commentarea}
                variant="outlined"
                onChange={handleChange}
                value={values.commentarea}
              />
              {stepData ? null : (
                <div className="submit-btn-container">
                  <button
                    className="next-button"
                    type="submit"
                    title="Start computations"
                  >
                    <img
                      src={cloudIcon}
                      className="cloud-icon"
                      alt="start simulation"
                    />{" "}
                    <span className="start-simulation">
                      Launch cloud computation
                    </span>
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
