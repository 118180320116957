import React, {useState, useEffect} from "react";
import "./RenameModal.scss";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useStoreActions } from "easy-peasy";
import { useSnackbar } from "notistack";

export default function RenameModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { name, type, id, setModalState } = props;
  const [newName, setNewName] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const renameAOI = useStoreActions(actions => actions.renameAOI);
  const renameTurbine = useStoreActions(actions => actions.renameTurbine);
  const renameConfiguration = useStoreActions(actions => actions.renameConfiguration);
  const renameRiskZone = useStoreActions(actions => actions.renameRiskZone);


  useEffect(() => {

  }, [newName])

  useEffect(() => {
    //function used to close the modal when clicking outside
   const addCloseContextFunc = (e) => {
       if(!e.target.closest(".rename-modal")) {
           setModalState(false);
       }
   };
   setTimeout(() => {
       window.addEventListener("click", addCloseContextFunc, false);
   }, 0)
   
   return () => {
       window.removeEventListener("click", addCloseContextFunc, false);
   }
}, [setModalState])
  
  const assignRenameFunctionByType = () => {
    switch (type) {
      case "aoi":
        return renameAOI
      case "turbine":
        return renameTurbine
      case "configuration":
        return renameConfiguration;
      case "risk_zone":
        return renameRiskZone;
      default:
        return null;
    }
  };

  const validationSchema = Yup.string()
      .required("Name is required")
      .typeError("Must be a valid string")
  ;

  const validateValue = (value) => {
    return validationSchema.validate(value)
    .then(() => {
      setHasErrors(false);
      setErrorMessage("");
      return true;
    })
    .catch(err => {
      setHasErrors(true);
      setErrorMessage(err.message);
      return false;
    });
  }

  return (
    <div className="rename-modal">
      <div className="rename-modal-title">
        Please type a new name for "{name}"
      </div>
      <TextField
          className="input-field"
          variant="outlined"
          type="text"
          name="newName"
          error={hasErrors}
          label={(hasErrors && errorMessage) || "New Name"}
          onChange={e => {
            setNewName(e.target.value)
            validateValue(e.target.value)
            }}
          value={newName}
          
        />
      <div className="rename-modal-choice">
        <div
          onClick={ () => {
            
            validateValue(newName).then(response => {
             
              if(response === true) {
                setModalState(false);
                  let renameItem = assignRenameFunctionByType();
                  let answer = renameItem({name: newName, id: id});
                answer
                .then(res => {
                  if (res && res.err === true) {
                    enqueueSnackbar("Could not rename item, this might be caused by server issues", {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      }
                    })
                  } else if (res && res.err === false) {              
                    enqueueSnackbar("Item renamed succesfully", {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      }
                    })
                  }
                })
                .catch(err => console.log("ERR Found => ", err))
            }
            });   
          }
          }
          className="rename-modal-confirm"
        >
          Rename
        </div>
        <div
          onClick={() => {
            setModalState(false);
          }}
          className="rename-modal-cancel"
        >
          Cancel
        </div>
      </div>
    </div>
  );
}
