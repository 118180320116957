import { Auth } from "aws-amplify";
import { apiUrl } from "../service";

export async function getProjects() {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}get_user_data`, {
      method: "GET",
      headers,
    });
    if (response.status === 200) {
      let projects = await response.json();
      projects.sort((a, b) => {
        let aDate = new Date(a.date);
        let bDate = new Date(b.date);
        return aDate - bDate;
      });
      projects.forEach((proj) => {
        if (proj.wind_turbines) {
          proj.wind_turbines.sort((a, b) => {
            let aDate = new Date(a.date);
            let bDate = new Date(b.date);
            return aDate - bDate;
          });
          proj.wind_turbines.forEach((wt) => {
            wt.configurations.sort((a, b) => {
              let aDate = new Date(a.date);
              let bDate = new Date(b.date);
              return aDate - bDate;
            });
            wt.configurations.forEach((conf) => {
              conf.risk_zones.sort((a, b) => {
                let aDate = new Date(a.date);
                let bDate = new Date(b.date);
                return aDate - bDate;
              });
            });
          });
        }
      });
      return projects;
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function addProject(project) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}insert_project`, {
      method: "POST",
      headers,
      body: JSON.stringify(project),
    });
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function deleteProject(projectId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(
      `${apiUrl}delete_project?project_id=${projectId}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.status === 200) {
      return "deleted";
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getLocationId(payload) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });
  let response;
  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    response = await fetch(`${apiUrl}get_location_id`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

// TAKES
// {
//   "area_name": "Toto",
//   "area_location": [109715, 202592],
//   "project_id": "7d9cc224-9729-4c4b-bcdb-cc7f3f9ab60e"
// }
// Returns AOI ID
export async function addAOI(aoi) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}insert_area_of_interest`, {
      method: "POST",
      headers,
      body: JSON.stringify(aoi),
    });
    if (response.status === 200) {
      let text = await response.text();
      return { message: text, err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

export async function deleteAOI(aoiId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(
      `${apiUrl}delete_area_of_interest?aoi_id=${aoiId}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.status === 200) {
      return "deleted";
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function reactivateAOI(aoiId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}reactivate?aoi_id=${aoiId}`, {
      method: "GET",
      headers,
    });
    if (response.status === 200) {
      let text = await response.text();
      return { message: text, err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

export async function renameAOI(payload) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}update_area_name`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      console.log("Status 200");
      return { message: "renamed", err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

export async function addTurbine(turbine) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });
  let responseObject;
  let response;
  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    response = await fetch(`${apiUrl}insert_wind_turbine`, {
      method: "POST",
      headers,
      body: JSON.stringify(turbine),
    });
    if (response.status === 200) {
      responseObject = { message: await response.text(), err: false };
    } else {
      responseObject = { message: await response.text(), err: true };
    }
  } catch (err) {
    responseObject = { message: err, err: true };
  } finally {
    return responseObject;
  }
}

export async function deleteTurbine(turbineId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(
      `${apiUrl}delete_wind_turbine?wt_id=${turbineId}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.status === 200) {
      return "deleted";
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function renameTurbine(payload) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}update_turbine_name`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      console.log("Status 200");
      return { message: "renamed", err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

export async function addConfiguration(configuration) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}insert_configuration`, {
      method: "POST",
      headers,
      body: JSON.stringify(configuration),
    });
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function addClonedConfiguration(
  configuration,
  copiedConfigurationId,
  riskZonesIdsArray
) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  let newConfigData = {
    new_configuration: configuration,
    copied_configuration_id: copiedConfigurationId,
    elements_ids: riskZonesIdsArray,
  };
  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}insert_copied_configuration`, {
      method: "POST",
      headers,
      body: JSON.stringify(newConfigData),
    });
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}
// Used to check if configuration has cloned all risk zones
export async function getConfigurationStatus(configId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });
  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(
      `${apiUrl}get_configuration?configuration_id=${configId}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function deleteConfiguration(configId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(
      `${apiUrl}delete_configuration?configuration_id=${configId}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.status === 200) {
      return "deleted";
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function renameConfiguration(payload) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}update_configuration_name`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      console.log("Status 200");
      return { message: "renamed", err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

export async function addRiskZone(riskZone) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}insert_risk_zone`, {
      method: "POST",
      headers,
      body: JSON.stringify(riskZone),
    });
    if (response.status === 200) {
      let text = await response.text();
      return { message: text, err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

export async function deleteRiskZone(riskZoneId) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(
      `${apiUrl}delete_risk_zone?element_id=${riskZoneId}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.status === 200) {
      return "deleted";
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function renameRiskZone(payload) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}update_element_name`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      return { message: "renamed", err: false };
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    return { message: err, err: true };
  }
}

// Data type to post
//  {
//   "turbine_location": [109715, 202592],
//   "hub_height": 137,
//   "day_ahead": {
//     "forcast_date": "2020-09-23T10:09:46.415809",
//     "nb_days": 5
//   }
// }
export async function getDayAheadWindClimate(payload) {
  const idToken = await Auth.currentSession().then((data) => {
    return data.getIdToken().getJwtToken();
  });

  try {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: idToken,
    });
    let response = await fetch(`${apiUrl}day_ahead_wind_climate`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      return response;
    } else {
      let text = await response.statusText;
      return { message: text, err: true };
    }
  } catch (err) {
    throw new Error(err);
  }
}
