import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useSnackbar } from "notistack";
import "./Results.scss";

export default function Results(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { fileId, userId, isSocietal } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [exceptionTxt, setExceptionTxt] = useState(null);

  useEffect(() => {
    if (fileId.includes("_exception.txt")) {
      Storage.get(fileId, {
        level: "protected",
        identityId: userId,
        download: true,
      })
        .then((txtFileFromS3) => {
          return JSON.parse(txtFileFromS3.Body.toString());
        })
        .then((resTxt) => {
          setExceptionTxt(resTxt);
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Error with exception file", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        });
    } else if (imageUrl === null) {
      Storage.get(fileId, { level: "protected", identityId: userId })
        .then((res) => {
          setImageUrl(res);
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Error while listing results.", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl, fileId]);

  if (imageUrl !== null) {
    // check if can display file and sets correct type to assign classname
    let canBeDisplayed = false;
    let containerType = "";
    if (fileId.split(" ")[1] === "individual") {
      containerType = "individual";
      if (isSocietal === false) {
        canBeDisplayed = true;
      }
    } else if (fileId.split(" ")[1] === "societal") {
      containerType = "societal";
      if (isSocietal === true) {
        canBeDisplayed = true;
      }
    } else if (fileId.includes("LIRA")) {
      canBeDisplayed = true;
      containerType = "lira";
    } else if (fileId.includes("IRPA") && fileId.includes("Individual")) {
      containerType = "individual";
      if (isSocietal === false) {
        canBeDisplayed = true;
      }
    } else if (fileId.includes("IRPA") && fileId.includes("Societal")) {
      containerType = "societal";
      if (isSocietal === true) {
        canBeDisplayed = true;
      }
    }
    return (
      <div
        key={`div-` + imageUrl}
        style={canBeDisplayed ? { display: "block" } : { display: "none" }}
        className={`img-container ${containerType}`}
      >
        <Zoom zoomMargin={40}>
          <img
            className="img-result"
            key={imageUrl}
            src={imageUrl}
            alt={fileId}
          />
        </Zoom>
      </div>
    );
  } else if (
    exceptionTxt !== null &&
    exceptionTxt.exception === "IceBellowDetection"
  ) {
    return (
      <div className="exception-message" key={`div-` + fileId}>
        With the current meteorological forecast, the amount of ice formed on
        the turbine is below the threshold of the detection system. As the
        turbine will keep operating, there is no ice fall risk, only a possible
        ice throw risk.
      </div>
    );
  } else {
    return <div></div>;
  }
}
