import React, {useEffect, useState} from 'react';
import './ContextMenu.scss';
import renameIcon from "../../../../assets/icons/pencil-edit.svg";
import deleteIcon from "../../../../assets/icons/trash-empty.svg";
import cloneIcon from "../../../../assets/icons/copy.svg";

export default function ContextMenu(props) {
    const { type, renameHandler, cloneHandler, deleteHandler, position, closeContextMenu} = props;
    const [calculatedPos, setCalculatedPos] = useState(false);

    useEffect(() => {
        if((window.innerHeight - position.y) < 100) {
            if(type === "project") {
                setCalculatedPos(position.y - 50);
            } else if(type === "wt" || type === "aoi") {
                setCalculatedPos(position.y - 75);
            } else {
                setCalculatedPos(position.y - 100);
            }
        } else {
                setCalculatedPos(position.y);
        }
        const addCloseContextFunc = (e) => {
            if(!e.target.className.includes("context-target")) {
                closeContextMenu();
            }
        };
        window.addEventListener("mousedown", addCloseContextFunc, false);
        
        return () => {
            window.removeEventListener("mousedown", addCloseContextFunc, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, closeContextMenu])

    // Context-target className is added to target the window event listener used to close the context menu :
    // if the clicked element doesn't containt the "context-target" class then the context menu will be close
    if(calculatedPos){
        return (
            <div className="sidebar-context-menu context-target" style={{left: position.x + "px", top: calculatedPos + "px"}}>
                {type !== "project" ? <div className="menu-item context-target" onClick={() => {
                    closeContextMenu();
                    renameHandler();
                    }}><img
                className="rename-icon context-target"
                src={renameIcon}
                alt="rename"
                title="Rename item" /><div className="rename-text context-target">Rename</div></div> : null}

                {type === "config" || type === "riskzone" ? <div className="menu-item context-target" onClick={() => {
                    closeContextMenu();
                    cloneHandler();
                    }}><img
                className="clone-icon context-target"
                src={cloneIcon}
                alt="clone"
                title="Clone item" /><div className="clone-text context-target">Duplicate</div></div> : null}

                <div className="menu-item context-target" onClick={() => {
                    closeContextMenu();
                    deleteHandler();
                    }}><img
                className="delete-icon context-target"
                src={deleteIcon}
                alt="delete"
                title="Delete item" /><div className="delete-text context-target">Delete</div></div>
            </div>
        )
    } else {
        return null;
    }
}
