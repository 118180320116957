import React, {useEffect} from 'react';
import "./WhatsNew.scss";
// import dayAheadImg from "../../../../assets/images/dayahead.jpg";
export default function WhatsNew({toggleNews, appVersion, lastNewFeaturesVersion}) {

    //Sets gray overlay when open
    useEffect(() => {
        document.getElementById("gray-overlay").style.display = "block";
        return () => {
            document.getElementById("gray-overlay").style.display = "none";
        }
    }, [])

    return (
        <div id="whats-new">
            <div className="news-title">What's new in version {lastNewFeaturesVersion} ?</div>
            <div className="news-content">
                <p className="intro">Greetings from the TRiceR team! Here is a quick summary of the features we have added to this version, we hope you will enjoy using our app!</p>
                <p className="feature-title">Day Ahead</p>
                <p className="feature-text">In any Configuration step, you can now select the day ahead method to fill the wind climate table
automatically which will use forecast data of the next 5 days to predict future ice fall risk.</p>
                {/* <img alt="day ahead news" src={dayAheadImg}></img> */}
                <p className="feature-title">Duplicate Configuration</p>
                <p className="feature-text">If you need to rerun calculations for all risk zones of a Configuration but you want to change some
values of the Configuration step, you can Duplicate this Configuration. This will prompt a new
Configuration page that allows you to input new data and then it will rerun the risk zones calculations
in batch of the old Configuration but with the new Configuration data this time.</p>
                <p className="feature-title">Duplicate Risk zones</p>
                <p className="feature-text">If you want to rerun a calculation for a Risk zone and change some input values without having to
recreate a whole new risk zone item, click on Duplicate for this risk zone. This will automatically fill a
new Risk zone page with your old data and let you change the values and even the path/area shape.
Then you can launch a new risk calculation.</p>
                <p className="feature-title">New side panel UI</p>
                <p className="feature-text">You can now Edit, Duplicate or Delete items more conveniently via the 3 “dots” button next to each
item and you can create a new item under a category via the “plus” button.</p>
                <p className="feature-title">New feature information box</p>
                <p className="feature-text">Every time there will be new updates in TRiceR, you will be notified by a pop up upon your first login.
You can access this information box again by clicking on the bell icon on the top right of the interface.</p>
            </div>
            <div onClick={() => {
                localStorage.setItem("patchNotesAcknowledged", true);
                localStorage.setItem("lastAcknowledgedVersion", appVersion);
                toggleNews(false);
                }} className="news-button">Close this window</div>
        </div>
    )
}
