import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./Step3b.scss";

export default function Step3b() {
  const setModalStep = useStoreActions(actions => actions.setModalStep);
  const currentItem = useStoreState(state => state.currentItem);
  useEffect(() => { }, [currentItem]);

  const goToStep4 = type => {
    setModalStep([4, type]);
  };

  return (
    <div id="step-3b">
      <div className="modal-title">Add element</div>

      <div className="path-selection">
        <span>Create a path</span>
        <span>Create an area</span>
        <div onClick={() => goToStep4("Path")} className="single-path">
          <svg id="path-icon" xmlns="http://www.w3.org/2000/svg" viewBox="10 10 100 100">
            <path
              id="pathpath"
              d="M67.5,42.5q-33.02809225279992,20.34330573439999,-7.5,29.99999999999997q20.029175771200016,11.1513722782,-20.5,42.99999999999997"
              style={{ stroke: "#b8b8b8", fill: "none", strokeWidth: "1px" }}
              transform="matrix(1 0 0 1 0 -18.6782)"
            />
          </svg>
        </div>
        <div className="poly-path">
          <svg id="poly-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 -10 100 100">
            <polygon
              style={{ stroke: "#b8b8b8", fill: "none", strokeWidth: "1px" }}
              points="15 25 10 60 80 80 85 10"
            />
          </svg>
        </div>
        <div></div>
        <div id="button-span-container">
          <div onClick={() => goToStep4("Fixed")} className="button-span" title="The fixed calculation method is used to model the use of a large area, where the risk level fluctuates over the area.">
            Fixed
          </div>{" "}
          |{" "}
          <div onClick={() => goToStep4("Average")} className="button-span" title="The average calculation method is used to assess the risk level of small areas where the risk level is expected to be the same on the whole area.">
            Average
          </div>{" "}
          |{" "}
          <div onClick={() => goToStep4("Parking")} className="button-span" title="The parking calculation method is used to assess the risk level of for example a parking. The risk level from the entrance to every point of the area is calculated.">
            Parking
          </div>
        </div>
      </div>
    </div>
  );
}
