import React from "react";
import AuthComp from "./components/Auth/AuthComp";
import Main from "./components/Main/Main";
import { useStoreState } from "easy-peasy";
import "./App.scss";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-pulse-sync.scss";

function App() {
  const authState = useStoreState((state) => state.auth.isLoggedIn);
  const isLoading = useStoreState((state) => state.isLoading);
  return (
    <div className="App">
      <div id="gray-overlay" style={{ display: "none" }}></div>
      <div
        style={isLoading ? { display: "grid" } : { display: "none" }}
        className="loader-container"
      >
        <Loader type="ball-pulse-sync" active />
      </div>
      {authState === false ? <AuthComp /> : <Main />}
    </div>
  );
}

export default App;
