import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import tricerLogo from "../../../assets/icons/logo.png";
import rightarrow from "../../../assets/icons/right-chevron.svg";
import projectIcon from "../../../assets/icons/folder.svg";
import aoiIcon from "../../../assets/icons/pin-round.svg";
import turbineIcon from "../../../assets/icons/wt.svg";
import configIcon from "../../../assets/icons/settings.svg";
import dotIcon from "../../../assets/icons/dot.svg";
import moreHorizontalIcon from "../../../assets/icons/more-horizontal.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import plusIconFat from "../../../assets/icons/plus-symbol-button.svg";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-pulse-sync.scss";
import DeleteModal from "./DeleteModal/DeleteModal";
import RenameModal from "./RenameModal/RenameModal";
import CloneModal from "./CloneModal/CloneModal";
import ContextMenu from "./ContextMenu/ContextMenu";
import "./SidePanel.scss";

export default function SidePanel() {
  const [showDeleteModal, setDeleteModalState] = useState(false);
  const [showRenameModal, setRenameModalState] = useState(false);
  const [showCloneModal, setCloneModalState] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({
    name: "",
    type: "",
    id: "",
  });
  const [itemToRename, setItemToRename] = useState({
    name: "",
    type: "",
    id: "",
  });

  const [itemToClone, setItemToClone] = useState({
    name: "",
    id: "",
    riskData: {},
    projects: [],
    project_id: "",
    aoi_id: "",
    turbine_id: "",
    config_id: "",
  });
  const [contextMenuInfo, setContextMenuInfo] = useState({
    visibility: false,
    type: null,
    renameHandler: null,
    cloneHandler: null,
    deleteHandler: null,
    position: null,
  });

  const projects = useStoreState((state) => state.projects);
  const setModalStep = useStoreActions((actions) => actions.setModalStep);
  const setStepData = useStoreActions((actions) => actions.setStepData);
  const currentItem = useStoreState((state) => state.currentItem);
  const setCurrentItem = useStoreActions((actions) => actions.setCurrentItem);
  const getProjects = useStoreActions((actions) => actions.getProjects);
  const setWindData = useStoreActions((actions) => actions.setWindData);
  const sidebarIsLoadingProjects = useStoreState(
    (state) => state.sidebarIsLoadingProjects
  );
  const sidebarOpenItems = useStoreState((state) => state.sidebarOpenItems);
  const setSidebarOpenItems = useStoreActions(
    (actions) => actions.setSidebarOpenItems
  );
  const checkIfAoiNeedsReactivation = useStoreActions(
    (actions) => actions.checkIfAoiNeedsReactivation
  );
  const setAoiNeedsReactivationToFalse = useStoreActions(
    (actions) => actions.setAoiNeedsReactivationToFalse
  );
  const selectionColor = "rgb(42 140 234)";

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() => {}, [projects]);

  return (
    <div className="side-panel">
      {showRenameModal ? (
        <RenameModal
          setModalState={setRenameModalState}
          name={itemToRename.name}
          type={itemToRename.type}
          id={itemToRename.id}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal
          setModalState={setDeleteModalState}
          name={itemToDelete.name}
          type={itemToDelete.type}
          id={itemToDelete.id}
        />
      ) : null}
      {showCloneModal ? (
        <CloneModal
          setModalState={setCloneModalState}
          name={itemToClone.name}
          id={itemToClone.id}
          riskData={itemToClone.riskData}
          projects={itemToClone.projects}
          project_id={itemToClone.project_id}
          aoi_id={itemToClone.aoi_id}
          turbine_id={itemToClone.turbine_id}
          config_id={itemToClone.config_id}
        />
      ) : null}
      {contextMenuInfo.visibility === true ? (
        <ContextMenu
          visibility={contextMenuInfo.visibility}
          type={contextMenuInfo.type}
          renameHandler={contextMenuInfo.renameHandler}
          deleteHandler={contextMenuInfo.deleteHandler}
          cloneHandler={contextMenuInfo.cloneHandler}
          position={contextMenuInfo.position}
          closeContextMenu={function () {
            return setContextMenuInfo({
              visibility: false,
              type: null,
              renameHandler: null,
              cloneHandler: null,
              deleteHandler: null,
              position: null,
            });
          }}
        />
      ) : null}

      <div className="icefall-title">
        <img className="tricer-logo" src={tricerLogo} alt="logo icefall"></img>
      </div>
      <div className="curved-bottom">
        <svg
          preserveAspectRatio="none"
          width="100%"
          height="20px"
          viewBox="0 0 800 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="transparent"
            stroke="#2b76b9"
            strokeWidth="10"
            d="M0,0 C200,150 600,150 800,0"
          ></path>
        </svg>
      </div>
      <div
        onClick={() => {
          setStepData(null);
          setCurrentItem(["projectId", null]);
          setCurrentItem(["aoiId", null]);
          setCurrentItem(["turbineId", null]);
          setCurrentItem(["calculationId", null]);
          setCurrentItem(["elementId", null]);
          setModalStep([1, ""]);
        }}
        className="new-project-btn"
        title="New Project"
      >
        <img className="new-project-icon" src={plusIconFat} alt="new project" />
        <span>New</span>
      </div>
      {sidebarIsLoadingProjects ? (
        <Loader type="ball-pulse-sync" active />
      ) : (
        <div className="projects-list">
          {projects &&
            projects.map((project) => (
              <div
                className={
                  currentItem.projectId === project.project_id
                    ? "tree selected-tree"
                    : "tree"
                }
                key={"div-" + project.project_id}
              >
                <img
                  className="rotating-item"
                  style={
                    project.areas_of_interest &&
                    project.areas_of_interest.length > 0
                      ? sidebarOpenItems.items.includes(project.project_id)
                        ? {
                            transform: "rotate(90deg)",
                            width: "10px",
                            visibility: "visible",
                          }
                        : {
                            transform: "none",
                            width: "10px",
                            visibility: "visible",
                          }
                      : { visibility: "hidden" }
                  }
                  onClick={() => setSidebarOpenItems(project.project_id)}
                  src={rightarrow}
                  alt="drop arrow"
                />
                <div className="project-level-grid">
                  <img
                    className="project-icon"
                    src={projectIcon}
                    alt="project icon"
                  />
                  <span
                    className="projectname"
                    style={
                      currentItem.projectId === project.project_id
                        ? { color: selectionColor }
                        : { color: "rgb(96 96 96)" }
                    }
                    onClick={() => {
                      setAoiNeedsReactivationToFalse();
                      setStepData(null);
                      setCurrentItem(["projectId", project.project_id]);
                      setCurrentItem(["aoiId", null]);
                      setCurrentItem(["turbineId", null]);
                      setCurrentItem(["calculationId", null]);
                      setCurrentItem(["elementId", null]);
                      setModalStep([null]);
                    }}
                    title={`Project : ${project.project_name}, Author : ${
                      project.user_id.split("-")[1]
                    }`}
                    id={project.projectId}
                  >
                    <b>{" " + project.project_name}</b>
                  </span>
                  <div className="button-container">
                    <div
                        className="more-container"
                        style={
                          currentItem.projectId === project.project_id &&
                          currentItem.aoiId === null &&
                          currentItem.turbineId === null &&
                          currentItem.calculationId === null &&
                          currentItem.elementId === null
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                        }
                    >
                      <img
                          className="moreIcon"
                          onClick={(e) => {
                            setContextMenuInfo({
                              visibility: true,
                              type: "project",
                              renameHandler: null,
                              cloneHandler: null,
                              deleteHandler: function () {
                                setItemToDelete({
                                  name: project.project_name,
                                  type: "project",
                                  id: project.project_id,
                                });
                                setDeleteModalState(true);
                              },
                              position: {
                                x: e.target.getBoundingClientRect().left + 11,
                                y: e.target.getBoundingClientRect().top + 13,
                              },
                            });
                          }}
                          src={moreHorizontalIcon}
                          alt="options"
                          title="Show options"
                      />
                    </div>
                    <div
                        className="plus-container"
                        style={
                          currentItem.projectId === project.project_id &&
                          currentItem.aoiId === null &&
                          currentItem.turbineId === null &&
                          currentItem.calculationId === null &&
                          currentItem.elementId === null
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                        }
                    >
                      <img
                          className="plusIcon"
                          onClick={() => {
                            setAoiNeedsReactivationToFalse();
                            setStepData(null);
                            setCurrentItem(["projectId", project.project_id]);
                            setCurrentItem(["aoiId", null]);
                            setCurrentItem(["turbineId", null]);
                            setCurrentItem(["calculationId", null]);
                            setCurrentItem(["elementId", null]);
                            setModalStep(["1b", project.project_id]);
                          }}
                          src={plusIcon}
                          alt="add aoi"
                          title="Add area of interest"
                      />
                    </div>
                  </div>
                </div>
                <ul
                  className="project-level-ul"
                  style={
                    sidebarOpenItems.items.includes(project.project_id)
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  key={project.project_id}
                >
                  {project.areas_of_interest &&
                    project.areas_of_interest.map((aoi) => (
                      <li className="aoi-list" key={aoi.aoi_id}>
                        <div className="aoi-level-tree">
                          <img
                            className="rotating-item"
                            style={
                              aoi.wind_turbines && aoi.wind_turbines.length > 0
                                ? sidebarOpenItems.items.includes(aoi.aoi_id)
                                  ? {
                                      transform: "rotate(90deg)",
                                      width: "10px",
                                      visibility: "visible",
                                    }
                                  : {
                                      transform: "none",
                                      width: "10px",
                                      visibility: "visible",
                                    }
                                : { visibility: "hidden" }
                            }
                            onClick={() => setSidebarOpenItems(aoi.aoi_id)}
                            alt="drop arrow"
                            src={rightarrow}
                          />
                          <img
                            className="aoi-icon"
                            style={{ width: "17px" }}
                            alt="aoi icon"
                            src={aoiIcon}
                          />
                          <div
                            className="aoi-level-grid"
                            style={{ alignSelf: "center" }}
                          >
                            {
                              <span
                                id={aoi.aoi_id}
                                style={
                                  currentItem.aoiId === aoi.aoi_id
                                    ? { color: selectionColor }
                                    : { color: "rgb(96 96 96)" }
                                }
                                className="aoiname"
                                onClick={() => {
                                  setAoiNeedsReactivationToFalse();
                                  setCurrentItem([
                                    "projectId",
                                    project.project_id,
                                  ]);
                                  setCurrentItem(["aoiId", aoi.aoi_id]);
                                  setCurrentItem([
                                    "use_latlon",
                                    aoi.use_latlon,
                                  ]);
                                  setCurrentItem(["turbineId", null]);
                                  setCurrentItem(["calculationId", null]);
                                  setCurrentItem(["elementId", null]);
                                  setStepData(aoi.aoi_id);
                                  setModalStep(["1b"]);
                                }}
                                title={`Author : ${aoi.user_id.split("-")[1]}`}
                              >
                                {" " + aoi.area_name}
                              </span>
                            }
                            <div className="button-container">
                              <div
                                  className="more-container"
                                  style={
                                    currentItem.aoiId === aoi.aoi_id &&
                                    currentItem.turbineId === null &&
                                    currentItem.calculationId === null &&
                                    currentItem.elementId === null
                                        ? { visibility: "visible" }
                                        : { visibility: "hidden" }
                                  }
                              >
                                <img
                                    className="moreIcon"
                                    onClick={(e) => {
                                      setContextMenuInfo({
                                        visibility: true,
                                        type: "aoi",
                                        renameHandler: function () {
                                          setItemToRename({
                                            name: aoi.area_name,
                                            type: "aoi",
                                            id: aoi.aoi_id,
                                          });
                                          setRenameModalState(true);
                                        },
                                        cloneHandler: null,
                                        deleteHandler: function () {
                                          setItemToDelete({
                                            name: aoi.area_name,
                                            type: "aoi",
                                            id: aoi.aoi_id,
                                          });
                                          setDeleteModalState(true);
                                        },
                                        position: {
                                          x:
                                              e.target.getBoundingClientRect().left +
                                              11,
                                          y:
                                              e.target.getBoundingClientRect().top +
                                              13,
                                        },
                                      });
                                    }}
                                    src={moreHorizontalIcon}
                                    alt="options"
                                    title="Show options"
                                />
                              </div>
                              <div
                                  className="plus-container"
                                  style={
                                    currentItem.aoiId === aoi.aoi_id &&
                                    currentItem.turbineId === null &&
                                    currentItem.calculationId === null &&
                                    currentItem.elementId === null
                                        ? { visibility: "visible" }
                                        : { visibility: "hidden" }
                                  }
                              >
                                <img
                                    className="plusIcon"
                                    onClick={() => {
                                      setStepData(null);
                                      setCurrentItem([
                                        "projectId",
                                        project.project_id,
                                      ]);
                                      setCurrentItem(["aoiId", aoi.aoi_id]);
                                      setCurrentItem(["turbineId", null]);
                                      setCurrentItem(["calculationId", null]);
                                      setCurrentItem(["elementId", null]);
                                      setModalStep([2, aoi.aoi_id]);
                                      checkIfAoiNeedsReactivation();
                                    }}
                                    src={plusIcon}
                                    alt="add wind turbine"
                                    title="Set up a new turbine in the area under study. Specify the dimensions and exact location."
                                />
                              </div>
                            </div>
                          </div>
                          <ul
                            className="aoi-level-ul"
                            style={
                              sidebarOpenItems.items.includes(aoi.aoi_id)
                                ? { display: "block" }
                                : { display: "none" }
                            }
                            key={aoi.aoi_id}
                          >
                            {aoi.wind_turbines &&
                              aoi.wind_turbines.map((turbine) => (
                                <li
                                  className="turbine-list"
                                  key={turbine.wt_id}
                                >
                                  <div className="turbine-level-tree">
                                    <img
                                      className="rotating-item"
                                      style={
                                        turbine.configurations &&
                                        turbine.configurations.length > 0
                                          ? sidebarOpenItems.items.includes(
                                              turbine.wt_id
                                            )
                                            ? {
                                                transform: "rotate(90deg)",
                                                width: "10px",
                                                visibility: "visible",
                                              }
                                            : {
                                                transform: "none",
                                                width: "10px",
                                                visibility: "visible",
                                              }
                                          : { visibility: "hidden" }
                                      }
                                      onClick={() =>
                                        setSidebarOpenItems(turbine.wt_id)
                                      }
                                      alt="drop arrow"
                                      src={rightarrow}
                                    />
                                    <img
                                      className="turbine-icon"
                                      style={{ width: "18px" }}
                                      alt="turbine icon"
                                      src={turbineIcon}
                                    />
                                    <div
                                      className="turbine-level-grid"
                                      style={{ alignSelf: "center" }}
                                    >
                                      {
                                        <span
                                          id={turbine.wt_id}
                                          style={
                                            currentItem.turbineId ===
                                            turbine.wt_id
                                              ? { color: selectionColor }
                                              : { color: "rgb(96 96 96)" }
                                          }
                                          className="turbname"
                                          onClick={() => {
                                            setAoiNeedsReactivationToFalse();
                                            setCurrentItem([
                                              "projectId",
                                              project.project_id,
                                            ]);
                                            setCurrentItem([
                                              "aoiId",
                                              aoi.aoi_id,
                                            ]);
                                            setCurrentItem([
                                              "turbineId",
                                              turbine.wt_id,
                                            ]);
                                            setCurrentItem([
                                              "calculationId",
                                              null,
                                            ]);
                                            setCurrentItem(["elementId", null]);
                                            setStepData(turbine.wt_id);
                                            setModalStep([2]);
                                          }}
                                          title={`Author : ${
                                            turbine.user_id.split("-")[1]
                                          }`}
                                        >
                                          {" " + turbine.turbine_name}
                                        </span>
                                      }
                                      <div className="button-container">
                                        <div
                                            className="more-container"
                                            style={
                                              currentItem.turbineId ===
                                              turbine.wt_id &&
                                              currentItem.calculationId === null &&
                                              currentItem.elementId === null
                                                  ? { visibility: "visible" }
                                                  : { visibility: "hidden" }
                                            }
                                        >
                                          <img
                                              className="moreIcon"
                                              onClick={(e) => {
                                                setContextMenuInfo({
                                                  visibility: true,
                                                  type: "wt",
                                                  renameHandler: function () {
                                                    setItemToRename({
                                                      name: turbine.turbine_name,
                                                      type: "turbine",
                                                      id: turbine.wt_id,
                                                    });
                                                    setRenameModalState(true);
                                                  },
                                                  cloneHandler: null,
                                                  deleteHandler: function () {
                                                    setItemToDelete({
                                                      name: turbine.turbine_name,
                                                      type: "turbine",
                                                      id: turbine.wt_id,
                                                    });
                                                    setDeleteModalState(true);
                                                  },
                                                  position: {
                                                    x:
                                                        e.target.getBoundingClientRect()
                                                            .left + 11,
                                                    y:
                                                        e.target.getBoundingClientRect()
                                                            .top + 13,
                                                  },
                                                });
                                              }}
                                              src={moreHorizontalIcon}
                                              alt="options"
                                              title="Show options"
                                          />
                                        </div>
                                        <div
                                            className="plus-container"
                                            style={
                                              currentItem.turbineId ===
                                              turbine.wt_id &&
                                              currentItem.calculationId === null &&
                                              currentItem.elementId === null
                                                  ? { visibility: "visible" }
                                                  : { visibility: "hidden" }
                                            }
                                        >
                                          <img
                                              className="plusIcon"
                                              onClick={() => {
                                                setStepData(null);
                                                setCurrentItem([
                                                  "projectId",
                                                  project.project_id,
                                                ]);
                                                setCurrentItem([
                                                  "aoiId",
                                                  aoi.aoi_id,
                                                ]);
                                                setCurrentItem([
                                                  "turbineId",
                                                  turbine.wt_id,
                                                ]);
                                                setCurrentItem([
                                                  "calculationId",
                                                  null,
                                                ]);
                                                setCurrentItem(["elementId", null]);
                                                setWindData(null);
                                                setModalStep([3]);
                                                checkIfAoiNeedsReactivation();
                                              }}
                                              src={plusIcon}
                                              alt="add configuration"
                                              title="Set up a new configuration of wind turbine. Specify the position of the rotor, safety features and wind data."
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <ul
                                      className="turbine-level-ul"
                                      style={
                                        sidebarOpenItems.items.includes(
                                          turbine.wt_id
                                        )
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      key={turbine.wt_id}
                                    >
                                      {turbine.configurations &&
                                        turbine.configurations.map(
                                          (configuration) => (
                                            <li
                                              className="config-list"
                                              key={
                                                configuration.configuration_id
                                              }
                                            >
                                              <div className="config-level-tree">
                                                <img
                                                  className="rotating-item"
                                                  style={
                                                    configuration.risk_zones &&
                                                    configuration.risk_zones
                                                      .length > 0
                                                      ? sidebarOpenItems.items.includes(
                                                          configuration.configuration_id
                                                        )
                                                        ? {
                                                            transform:
                                                              "rotate(90deg)",
                                                            width: "10px",
                                                            visibility:
                                                              "visible",
                                                          }
                                                        : {
                                                            transform: "none",
                                                            width: "10px",
                                                            visibility:
                                                              "visible",
                                                          }
                                                      : { visibility: "hidden" }
                                                  }
                                                  onClick={() =>
                                                    setSidebarOpenItems(
                                                      configuration.configuration_id
                                                    )
                                                  }
                                                  alt="drop arrow"
                                                  src={rightarrow}
                                                />
                                                <img
                                                  className="configuration-icon"
                                                  style={{ width: "18px" }}
                                                  alt="turbine icon"
                                                  src={configIcon}
                                                />
                                                <div
                                                  className="config-level-grid"
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  <span
                                                    id={
                                                      configuration.configuration_id
                                                    }
                                                    className="calcname"
                                                    style={
                                                      currentItem.calculationId ===
                                                      configuration.configuration_id
                                                        ? {
                                                            color:
                                                              selectionColor,
                                                          }
                                                        : {
                                                            color:
                                                              "rgb(96 96 96)",
                                                          }
                                                    }
                                                    onClick={() => {
                                                      setAoiNeedsReactivationToFalse();
                                                      setCurrentItem([
                                                        "projectId",
                                                        project.project_id,
                                                      ]);
                                                      setCurrentItem([
                                                        "turbineId",
                                                        turbine.wt_id,
                                                      ]);
                                                      setCurrentItem([
                                                        "aoiId",
                                                        aoi.aoi_id,
                                                      ]);
                                                      setCurrentItem([
                                                        "calculationId",
                                                        configuration.configuration_id,
                                                      ]);
                                                      setCurrentItem([
                                                        "elementId",
                                                        null,
                                                      ]);
                                                      setWindData(null);
                                                      setStepData(
                                                        configuration.configuration_id
                                                      );
                                                      setModalStep([3]);
                                                    }}
                                                    title={`Author : ${
                                                      configuration.user_id.split(
                                                        "-"
                                                      )[1]
                                                    }`}
                                                  >
                                                    {" " +
                                                      configuration.configuration_name}
                                                  </span>
                                                  <div className="button-container">
                                                    <div
                                                        className="more-container"
                                                        style={
                                                          currentItem.calculationId ===
                                                          configuration.configuration_id &&
                                                          currentItem.elementId ===
                                                          null
                                                              ? {
                                                                visibility:
                                                                    "visible",
                                                              }
                                                              : {
                                                                visibility:
                                                                    "hidden",
                                                              }
                                                        }
                                                    >
                                                      <img
                                                          className="moreIcon"
                                                          onClick={(e) => {
                                                            setContextMenuInfo({
                                                              visibility: true,
                                                              type: "config",
                                                              renameHandler:
                                                                  function () {
                                                                    setItemToRename({
                                                                      name: configuration.configuration_name,
                                                                      type: "configuration",
                                                                      id: configuration.configuration_id,
                                                                    });
                                                                    setRenameModalState(
                                                                        true
                                                                    );
                                                                  },
                                                              cloneHandler:
                                                                  function () {
                                                                    setStepData(null);
                                                                    setRenameModalState(
                                                                        false
                                                                    );
                                                                    setDeleteModalState(
                                                                        false
                                                                    );
                                                                    setCloneModalState(
                                                                        false
                                                                    );
                                                                    setModalStep([
                                                                      3,
                                                                      true,
                                                                      configuration.configuration_id,
                                                                      configuration.configuration_name,
                                                                    ]);
                                                                  },
                                                              deleteHandler:
                                                                  function () {
                                                                    setItemToDelete({
                                                                      name: configuration.configuration_name,
                                                                      type: "configuration",
                                                                      id: configuration.configuration_id,
                                                                    });
                                                                    setDeleteModalState(
                                                                        true
                                                                    );
                                                                  },
                                                              position: {
                                                                x:
                                                                    e.target.getBoundingClientRect()
                                                                        .left + 11,
                                                                y:
                                                                    e.target.getBoundingClientRect()
                                                                        .top + 13,
                                                              },
                                                            });
                                                          }}
                                                          src={moreHorizontalIcon}
                                                          alt="options"
                                                          title="Show options"
                                                      />
                                                    </div>
                                                    <div
                                                        className="plus-container"
                                                        style={
                                                          currentItem.calculationId ===
                                                          configuration.configuration_id &&
                                                          currentItem.elementId ===
                                                          null
                                                              ? {
                                                                visibility:
                                                                    "visible",
                                                              }
                                                              : {
                                                                visibility:
                                                                    "hidden",
                                                              }
                                                        }
                                                    >
                                                      <img
                                                          className="plusIcon"
                                                          onClick={() => {
                                                            setStepData(null);
                                                            setCurrentItem([
                                                              "projectId",
                                                              project.project_id,
                                                            ]);
                                                            setCurrentItem([
                                                              "aoiId",
                                                              aoi.aoi_id,
                                                            ]);
                                                            setCurrentItem([
                                                              "turbineId",
                                                              turbine.wt_id,
                                                            ]);
                                                            setCurrentItem([
                                                              "calculationId",
                                                              configuration.configuration_id,
                                                            ]);
                                                            setCurrentItem([
                                                              "elementId",
                                                              null,
                                                            ]);
                                                            setModalStep(["3b"]);
                                                            checkIfAoiNeedsReactivation();
                                                          }}
                                                          src={plusIcon}
                                                          alt="add new riskzone"
                                                          title="Set up a new riskzone in the area of wind turbine."
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <ul
                                                  className="config-level-ul"
                                                  style={
                                                    sidebarOpenItems.items.includes(
                                                      configuration.configuration_id
                                                    )
                                                      ? { display: "block" }
                                                      : { display: "none" }
                                                  }
                                                  key={
                                                    configuration.configuration_id
                                                  }
                                                >
                                                  {configuration.risk_zones &&
                                                    configuration.risk_zones.map(
                                                      (riskZone) => (
                                                        <li
                                                          className="risk-zone-list"
                                                          key={
                                                            riskZone.element_id
                                                          }
                                                        >
                                                          <img
                                                            className="risk-zone-level-img"
                                                            style={{
                                                              width: "4px",
                                                              paddingBottom: 0,
                                                              paddingTop: "2px",
                                                            }}
                                                            alt="dot"
                                                            src={dotIcon}
                                                          />
                                                          <div
                                                            className="risk-zone-level-grid"
                                                            style={{
                                                              alignSelf:
                                                                "center",
                                                            }}
                                                          >
                                                            <span
                                                              id={
                                                                riskZone.element_id
                                                              }
                                                              className="elname"
                                                              style={
                                                                currentItem.elementId ===
                                                                riskZone.element_id
                                                                  ? {
                                                                      color:
                                                                        selectionColor,
                                                                    }
                                                                  : {
                                                                      color:
                                                                        "rgb(96 96 96)",
                                                                    }
                                                              }
                                                              onClick={() => {
                                                                setAoiNeedsReactivationToFalse();
                                                                setCurrentItem([
                                                                  "projectId",
                                                                  project.project_id,
                                                                ]);
                                                                setCurrentItem([
                                                                  "aoiId",
                                                                  aoi.aoi_id,
                                                                ]);
                                                                setCurrentItem([
                                                                  "turbineId",
                                                                  turbine.wt_id,
                                                                ]);
                                                                setCurrentItem([
                                                                  "calculationId",
                                                                  configuration.configuration_id,
                                                                ]);
                                                                setCurrentItem([
                                                                  "elementId",
                                                                  riskZone.element_id,
                                                                ]);
                                                                setStepData(
                                                                  riskZone.element_id
                                                                );
                                                                setModalStep([
                                                                  4,
                                                                ]);
                                                              }}
                                                              title={`Author : ${
                                                                riskZone.user_id.split(
                                                                  "-"
                                                                )[1]
                                                              }`}
                                                            >
                                                              {" " +
                                                                riskZone.element_name}
                                                            </span>
                                                            <div
                                                              className="more-container"
                                                              style={
                                                                currentItem.elementId ===
                                                                riskZone.element_id
                                                                  ? {
                                                                      visibility:
                                                                        "visible",
                                                                    }
                                                                  : {
                                                                      visibility:
                                                                        "hidden",
                                                                    }
                                                              }
                                                            >
                                                              <img
                                                                className="moreIcon"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  setContextMenuInfo(
                                                                    {
                                                                      visibility: true,
                                                                      type: "config",
                                                                      renameHandler:
                                                                        function () {
                                                                          setItemToRename(
                                                                            {
                                                                              name: riskZone.element_name,
                                                                              type: "risk_zone",
                                                                              id: riskZone.element_id,
                                                                            }
                                                                          );
                                                                          setCloneModalState(
                                                                            false
                                                                          );
                                                                          setDeleteModalState(
                                                                            false
                                                                          );
                                                                          setRenameModalState(
                                                                            true
                                                                          );
                                                                        },
                                                                      cloneHandler:
                                                                        function () {
                                                                          setItemToClone(
                                                                            {
                                                                              name: riskZone.element_name,
                                                                              id: riskZone.element_id,
                                                                              riskData:
                                                                                riskZone,
                                                                              projects:
                                                                                projects,
                                                                              project_id:
                                                                                project.project_id,
                                                                              aoi_id:
                                                                                aoi.aoi_id,
                                                                              turbine_id:
                                                                                turbine.wt_id,
                                                                              config_id:
                                                                                configuration.configuration_id,
                                                                            }
                                                                          );
                                                                          setRenameModalState(
                                                                            false
                                                                          );
                                                                          setDeleteModalState(
                                                                            false
                                                                          );
                                                                          setCloneModalState(
                                                                            true
                                                                          );
                                                                        },
                                                                      deleteHandler:
                                                                        function () {
                                                                          setItemToDelete(
                                                                            {
                                                                              name: riskZone.element_name,
                                                                              type: "risk_zone",
                                                                              id: riskZone.element_id,
                                                                            }
                                                                          );
                                                                          setCloneModalState(
                                                                            false
                                                                          );
                                                                          setRenameModalState(
                                                                            false
                                                                          );
                                                                          setDeleteModalState(
                                                                            true
                                                                          );
                                                                        },
                                                                      position:
                                                                        {
                                                                          x:
                                                                            e.target.getBoundingClientRect()
                                                                              .left +
                                                                            9,
                                                                          y:
                                                                            e.target.getBoundingClientRect()
                                                                              .top +
                                                                            10,
                                                                        },
                                                                    }
                                                                  );
                                                                }}
                                                                src={
                                                                  moreHorizontalIcon
                                                                }
                                                                alt="options"
                                                                title="Show options"
                                                              />
                                                            </div>
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                </ul>
                                              </div>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
