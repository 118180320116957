import React, {useState, useEffect} from 'react';
import "./CloneModal.scss";
import { Select, Box } from '@material-ui/core';
import { useStoreActions } from "easy-peasy";


export default function CloneModal(props) {
    const { riskData, projects, project_id, aoi_id, turbine_id, config_id, setModalState } = props;
    const [config, setConfig] = useState('');
    const [selectItems, setSelectItems] = useState([]);
    const setModalStep = useStoreActions(actions => actions.setModalStep);
    const setStepData = useStoreActions(actions => actions.setStepData);
    const setCurrentItem = useStoreActions(actions => actions.setCurrentItem);
   
    // Sorts, sets default config in dropdown menu, sets list of configs in dropdown menu
    useEffect(() => {
        projects.forEach(proj => {
            if (proj.project_id === project_id) {
                proj.areas_of_interest.forEach(aoi => {
                    if (aoi.aoi_id === aoi_id) {
                        aoi.wind_turbines.forEach(turbine => {
                            if (turbine.wt_id === turbine_id) {
                                turbine.configurations.forEach(config => {
                                    if (config.configuration_id === config_id) {
                                        setConfig(config.configuration_id);
                                        let itemsClone = selectItems;
                                        itemsClone.push(config);
                                        setSelectItems(itemsClone);
                                    } else {
                                        let itemsClone = selectItems;
                                        itemsClone.push(config);
                                        setSelectItems(itemsClone);
                                    } 
                                });
                            }
                        });
                    }
                })
            }
        })
}, [projects, project_id, aoi_id, turbine_id, config_id, selectItems])


    useEffect(() => {
         //function used to close the modal when clicking outside
        const addCloseContextFunc = (e) => {
            if(!e.target.closest(".clone-modal")) {
                setModalState(false);
            }
        };
        setTimeout(() => {
            window.addEventListener("click", addCloseContextFunc, false);
        }, 0)
        
        return () => {
            window.removeEventListener("click", addCloseContextFunc, false);
        }
    }, [setModalState])

    return (
        <div className="clone-modal">
            <div className="clone-modal-title">
                Please select the configuration you want to duplicate this risk zone to
            </div>
            <div className="config-dropdown">
            <Box className="select-custom-cont">
                <Select
                    native={true}
                    variant="outlined"
                    value={config}
                    onChange={e => {
                        setConfig(e.target.value)
                        }}
                >
                    {selectItems.map(item => <option key={"menu-item" + item.configuration_id} value={item.configuration_id}>{item.configuration_name}</option>)}
                </Select>
            </Box>
            </div>
            <div className="clone-modal-choice">
        <div
          onClick={() => {
            setCurrentItem(["calculationId", config]);
            setStepData(null);
            setModalStep([0]);
            // Timeout needed to make sure Step 4 rerenders, otherwise causes problems. 
            // Ex: clone a risk zone when you are viewing results => gets blank screen as it didn't have the time to rerender the component
            setTimeout(() => {
                setModalStep([4, riskData.calculation_method, riskData, config, true]);
                setModalState(false);
            }, 75)
           
            
          }}
          className="clone-modal-confirm"
        >
          Duplicate
        </div>
        <div
          onClick={() => {
            setModalState(false);
          }}
          className="clone-modal-cancel"
        >
          Cancel
        </div>
      </div>
        </div>
    )
}
