import React, { useEffect } from "react";
import L, { FeatureGroup } from "leaflet";
import "proj4leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import windturbine from "../../../../../../assets/images/cross.png";
import iconRetinaUrl from "../../../../../../assets/images/marker-icon-2x.png";
import iconUrl from "../../../../../../assets/images/marker-icon.png";
import shadowUrl from "../../../../../../assets/images/marker-shadow.png";

export default function LeafletMap(props) {
  const { coords, lambert } = props;

  useEffect(() => {
    if (!lambert) return;
    // Rerender map on change between different elements
    let mapBe = L.DomUtil.get("mapcont-be");
    if (mapBe) {
      mapBe.remove();
      mapBe = undefined;
      let mapPre = document.getElementById("map-be");
      if (mapPre) mapPre.innerHTML = '<div id="mapcont-be"></div>';
    }

    let mapWorld = L.DomUtil.get("mapcont-world");
    if (mapWorld) {
      mapWorld.remove();
      mapWorld = undefined;
      let mapPre = document.getElementById("map-world");
      if (mapPre) mapPre.innerHTML = '<div id="mapcont-world"></div>';
    }

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
    });

    let crs = new L.Proj.CRS(
      "EPSG:31370",
      "+proj=lcc " +
        "+lat_1=51.16666723333333 " +
        "+lat_2=49.8333339 " +
        "+lat_0=90 " +
        "+lon_0=4.367486666666666 " +
        "+x_0=150000.013 " +
        "+y_0=5400088.438 " +
        "+ellps=intl " +
        "+units=m " +
        "+towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747" +
        "+no_defs",
      {
        scales: [0.1, 0.25, 0.5, 0.75, 1, 2, 4, 8, 16],
        bounds: L.Bounds([14697.3, 20939.01], [297193.15, 246456.18]),
      }
    );
    let layer2 = new L.tileLayer.wms(
      "https://geoservices.wallonie.be/arcgis/services/IMAGERIE/ORTHO_2018/MapServer/WmsServer?",
      {
        layers: "0",
        transparent: true,
        format: "image/png",
      }
    );
    let layer1 = new L.tileLayer.wms("https://geo.api.vlaanderen.be/OFW/wms?", {
      layers: "OFW",
      transparent: true,
      format: "image/png",
      version: "1.3.0",
    });

    const lambertToLatLong = (x, y) => {
      return crs.unproject(L.point(x, y));
    };

    let drawnItems = new FeatureGroup();

    const mapOptions = {
      maxZoom: 8,
      minZoom: 0,
      zoom: 4,
      crs: crs,
      wheelPxPerZoomLevel: 120,
      zoomDelta: 1,
      touchExtend: false,
      preferCanvas: true,
    };
    let mymap = new L.map("mapcont-be", mapOptions);
    layer1.addTo(mymap);
    layer2.addTo(mymap);

    // let center = lambertToLatLong(parseInt(viewCoords[0]), parseInt(viewCoords[1]));
    let center = lambertToLatLong(
      parseInt(coords[0]) || 0,
      parseInt(coords[1]) || 0
    );
    let bounds = [
      [center.lat - 0.00012, center.lng - 0.00021],
      [center.lat + 0.00015, center.lng + 0.00021],
    ];
    L.imageOverlay(windturbine, bounds).addTo(mymap);
    L.imageOverlay(windturbine, bounds).bringToFront();

    // adding circle
    L.circle(
      lambertToLatLong(parseInt(coords[0]) || 0, parseInt(coords[1]) || 0),
      {
        color: "#67A4FC",
        fillColor: "#67A4FC",
        fillOpacity: 0.2,
        radius: 100,
      }
    ).addTo(drawnItems);

    mymap.addLayer(drawnItems);
    mymap.setView(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  return <div id="mapcont-be" key="mapcont-be"></div>;
}
