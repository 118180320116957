import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import uploadIcon from "../../../../../assets/icons/upload.png";
import WindTable from "./WindTable/WindTable";
import { v4 as uuid } from "uuid";
import {
  addClonedConfiguration,
  getConfigurationStatus,
  getDayAheadWindClimate,
} from "../../../../../service/projectsService";
import csv from "papaparse";
import csvTemplate from "../../../../../assets/files/template.csv";
import { useSnackbar } from "notistack";
import store from "../../../../../store/index";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-pulse-sync.scss";
import "./Step3.scss";

import { pdfUrl } from "../../../../../service";

export default function Step3(props) {
  const { isClone, cloneId, cloneName } = props;
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [riskZonesForCheckList, setRiskZonesForCheckList] = useState([]);
  const [checkedRiskZones, setCheckedRiskZones] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [duplicationCounter, setDuplicationCounter] = useState([0, 0]);
  const { enqueueSnackbar } = useSnackbar();
  const projects = useStoreState((state) => state.projects);
  const user = useStoreState((state) => state.user);
  const setSidebarIsLoadingProjects = useStoreActions(
    (actions) => actions.setSidebarIsLoadingProjects
  );
  const getProjects = useStoreActions((actions) => actions.getProjects);
  const setCurrentItem = useStoreActions((actions) => actions.setCurrentItem);
  const setSidebarOpenItems = useStoreActions(
    (actions) => actions.setSidebarOpenItems
  );
  const setModalStep = useStoreActions((actions) => actions.setModalStep);
  const addConfiguration = useStoreActions(
    (actions) => actions.addConfiguration
  );
  const stepData = useStoreState((state) => state.stepData);
  const setStepData = useStoreActions((actions) => actions.setStepData);
  const currentItem = useStoreState((state) => state.currentItem);
  const windData = useStoreState((state) => state.windData);
  const setWindData = useStoreActions((actions) => actions.setWindData);
  // Helper called to know what turbine coords are there to pass turbine data to day ahead call
  const setTurbineCoords = useStoreActions(
    (actions) => actions.setTurbineCoords
  );
  // Used on submit, data input into it when uploading csv with weather data
  const [tempData, setTempData] = useState({});
  // Used for custom file upload validation
  const [fileError, setFileError] = useState(false);
  // Used to check loading state of PDF file
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  // Used to set loader on Day ahead call and disable other inputs
  const [isDayAheadLoading, setIsDayAheadLoading] = useState(false);
  // Used to construct call with day ahead - false by default, changed when doing day ahead calculation, also fills data
  const [configType, setConfigTypeData] = useState([false, {}]);

  const windDirectionLibrary = [
    "N",
    "NNE",
    "ENE",
    "E",
    "ESE",
    "SSE",
    "S",
    "SSW",
    "WSW",
    "W",
    "WNW",
    "NNW",
  ];

  // Used to show loading animation when fetching PDF or Day Ahead
  useEffect(() => {}, [isPDFLoading]);

  // Rerenders on prop change for cloning purposes and adds risk zones list + refs
  useEffect(() => {
    if (isClone) {
      let arrayToPassToState = [];
      projects.forEach((proj) => {
        if (proj.project_id === currentItem.projectId) {
          proj.areas_of_interest.forEach((aoi) => {
            if (aoi.aoi_id === currentItem.aoiId) {
              aoi.wind_turbines.forEach((turbine) => {
                if (turbine.wt_id === currentItem.turbineId) {
                  turbine.configurations.forEach((config) => {
                    if (config.configuration_id === currentItem.calculationId) {
                      config.risk_zones.forEach((zone) => {
                        arrayToPassToState.push(zone);
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
      setRiskZonesForCheckList(arrayToPassToState);
    }

    return () => {
      setCheckedRiskZones([]);
      setAllChecked(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneId]);

  // This effect rerenders once we get the list of risk zones for the checkbox list
  useEffect(() => {}, [riskZonesForCheckList]);

  // Sets current turbine data in store to be used in day ahead call
  useEffect(() => {
    setTurbineCoords({
      projectId: currentItem.projectId,
      aoiId: currentItem.aoiId,
      turbineId: currentItem.turbineId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {}, [configType]);

  const getPdf = async (config_id) => {
    setIsPDFLoading(true);
    const pdf_id = uuid();
    // Noprod
    // let url = `https://aml095rza3.execute-api.eu-west-1.amazonaws.com/noprod/gen_pdf?configuration_id=${config_id}&pdf_id=${pdf_id}`;
    // Prod
    let url = `${pdfUrl}gen_pdf?configuration_id=${config_id}&pdf_id=${pdf_id}`;
    const idToken = await Auth.currentSession().then((data) => {
      return data.getIdToken().getJwtToken();
    });

    try {
      const headers = new Headers({
        "Content-Type": "application/json",
        Authorization: idToken,
      });
      let response = await fetch(url, {
        method: "GET",
        headers,
      });
      if (response.status === 200) {
        response
          .json()
          .then((res) => {
            return res.filename.split("protected/" + user + "/")[1];
          })
          .then((res) => {
            Amplify.Storage.get(res, {
              level: "protected",
              identityId: user,
            }).then((fileUrl) => {
              const link = document.createElement("a");
              link.href = fileUrl;
              link.target = "_blank";
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
            setIsPDFLoading(false);
          })
          .catch((err) => {
            console.log("Error when getting file from S3", err);
            enqueueSnackbar("Error when getting file from S3", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
          });
      } else {
        console.log("Error when generating file", response.message);
        enqueueSnackbar("Error when generating file", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        setIsPDFLoading(false);
        throw new Error(response.statusText);
      }
    } catch (err) {
      enqueueSnackbar(
        err.message +
          ": please ensure risk zones exist under this configuration",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }
      );
      setIsPDFLoading(false);
      console.log(err);
    }
  };

  const handleRiskzoneCheck = (e) => {
    if (checkedRiskZones.filter((v) => v === e.target.value).length > 0) {
      let temp = [...checkedRiskZones];
      temp = temp.filter((value) => value !== e.target.value);
      setCheckedRiskZones(temp);
    } else {
      let temp = [...checkedRiskZones];
      temp.push(e.target.value);
      setCheckedRiskZones(temp);
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    setFileError(false);
    if (e.target.files) {
      let files = e.target.files;
      let freqTotal = 0;
      if (files[0] !== undefined) {
        let fileExtension = files[0].type;
        if (fileExtension === "text/csv") {
          csv.parse(files[0], {
            complete: function (results) {
              //  Clean Up Results only to represent 5 columns withouth header
              let limitedResults = results.data
                .map((row) => row.slice(0, 5))
                .filter((item) => item.length > 1);

              limitedResults.shift();

              let windTable = [];
              // #Check number of Columns and Rows
              limitedResults.forEach((result, index) => {
                let createRow = [];
                // #Check if it is proper wind direction information

                if (!windDirectionLibrary.includes(result[0])) {
                  setFileError(true);

                  enqueueSnackbar(
                    `On line ${
                      index + 2
                    }, there is wrong wind direction information, information provided is ${
                      result[0]
                    }`,
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      persist: true,
                    }
                  );
                }

                // #Check m/s to be less then 75
                if (parseFloat(result[1].replace(/,/g, ".")) <= 75) {
                  createRow.push(result[1]);
                } else {
                  setFileError(true);

                  enqueueSnackbar(
                    `On line ${
                      index + 2
                    }, the wind speed exceded 75 m/s, wind conditions should be bellow this value. Value on this was ${
                      result[1]
                    }`,
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      persist: true,
                    }
                  );

                  createRow.push(result[1]);
                }
                // #Check k to be more then 0
                if (parseFloat(result[2].replace(/,/g, ".")) >= 0) {
                  createRow.push(result[2]);
                } else {
                  setFileError(true);

                  enqueueSnackbar(
                    `On line ${
                      index + 2
                    }, the k is bellow 0, k conditions should be above 0. Value on this was ${
                      result[2]
                    }`,
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      persist: true,
                    }
                  );
                  createRow.push(result[2]);
                }
                // #Check Freq to be less then 1

                let freqValue = parseFloat(result[3].replace(/,/g, "."));
                freqTotal += freqValue;
                freqTotal = +freqTotal.toFixed(2);

                if (parseFloat(result[3]) <= 1) {
                  createRow.push(result[3]);
                } else {
                  setFileError(true);

                  enqueueSnackbar(
                    `On line ${
                      index + 2
                    }, the frequency is equal of exceding 1, frequency should be bellow 1. Value on this was ${
                      result[3]
                    }`,
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      persist: true,
                    }
                  );

                  createRow.push(result[3]);
                }
                // #Check Sheer Expo
                if (parseFloat(result[4].replace(/,/g, ".")) <= 1) {
                  createRow.push(result[4]);
                } else {
                  setFileError(true);

                  enqueueSnackbar(
                    `On line ${
                      index + 1
                    }, the shear exponent is equal of exceding 1, shear exponent should be bellow 1. Value on this was ${
                      result[3]
                    }`,
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      persist: true,
                    }
                  );

                  createRow.push(result[4]);
                }

                windTable.push(createRow);
              });

              // #Check FreqTotal
              if (freqTotal !== 1) {
                setFileError(true);
                enqueueSnackbar(
                  `Total frequency is not equal to 1, it has to be fixed to perform proper caluations. Based on summing values total frequency is ${freqTotal}`,
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    persist: true,
                  }
                );
              }

              if (windTable.length !== 12) {
                setFileError(true);
                enqueueSnackbar(
                  `Total number of rows of data for wind is not equal 12, adjust file to have all 12 wind directions. Number of wind directions provided is ${windTable.length}`,
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    persist: true,
                  }
                );
              }

              setWindData(windTable);
              setTempData({ windData: windTable });
              setConfigTypeData([false, {}]);
            },
          });
        } else {
          setFileError(true);
          setWindData(null);
        }
      } else {
        setFileError(true);
        setWindData(null);
      }
    } else {
      setFileError(true);
      setWindData(null);
    }
  };

  const toggleYawDegreeInput = (bool) => {
    let els = document.getElementsByClassName("degree-input");
    if (bool === false)
      Object.keys(els).forEach((el) => (els[el].style.visibility = "hidden"));
    else
      Object.keys(els).forEach((el) => (els[el].style.visibility = "visible"));
  };

  const evalDayAhead = async () => {
    let turbineData = store.getState().turbineCoords;
    let currentTime = new Date(Date.now()).toISOString().split("Z")[0];
    let payload = {
      turbine_location: [parseInt(turbineData[0]), parseInt(turbineData[1])],
      hub_height: parseInt(turbineData[2]),
      day_ahead: {
        forcast_date: currentTime,
        nb_days: 5,
      },
    };
    try {
      const dayAheadData = await getDayAheadWindClimate(payload);
      const parsedData = await dayAheadData.json();
      setWindData(parsedData.wind_climate);
      setTempData({ windData: parsedData.wind_climate });
      setConfigTypeData([true, payload.day_ahead]);
      setIsDayAheadLoading(false);
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      setWindData(null);
      setConfigTypeData([false, {}]);
      setIsDayAheadLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    calcname: Yup.string()
      .required("Name of configuration")
      .test('no-forbidden-chars', 'The configuration name cannot contain /, ? or & characters', (value) => {
        return !/[/?&]/.test(value);
      })
      .typeError("Must be a string"),
    yawdegree: Yup.number()
      .required("Required")
      .min(0, "Minimum is 0")
      .max(360, "Maximum is 360")
      .typeError("Must be a number"),
    yawtype: Yup.string()
      .oneOf(["free", "fixed"])
      .required("Select a Yaw Type"),
    withprevstop: Yup.string()
      .oneOf(["with", "without"])
      .required("Select Stop Type"),
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      calcname: (stepData && stepData.configuration_name) || "",
      yawtype: (stepData && stepData.fixed_yaw) || "",
      yawdegree: (stepData && stepData.yaw_angle) || 0,
      withprevstop:
        stepData && stepData.preventive_stop === true
          ? "with"
          : stepData && stepData.preventive_stop === false
          ? "without"
          : "",
    },
    validationSchema,
    async onSubmit(data) {
      if (tempData.windData) {
        let finalizingWindData = tempData.windData.map((el) => {
          return el.map((elt) => {
            if (typeof elt === "number") {
              let newEl = "" + elt;
              return newEl;
            } else {
              return elt;
            }
          });
        });
        // checks if type is day_ahead
        if (configType[0] === true) {
          data.day_ahead = configType[1];
        }
        data.calculationId = uuid();
        data.projectId = currentItem.projectId;
        data.aoiId = currentItem.aoiId;
        data.turbineId = currentItem.turbineId;
        data.use_latlon = currentItem.use_latlon;
        data.windData = finalizingWindData;
        if (isClone) {
          setSidebarIsLoadingProjects(true);
          setIsDuplicating(true);
          let castConf = {
            configuration_name: data.calcname,
            project_id: data.projectId,
            aoi_id: data.aoiId,
            wt_id: data.turbineId,
            yaw_angle: data.yawdegree,
            wind_climate: data.windData,
            preventive_stop:
              data.withprevstop === "with"
                ? true
                : data.withprevstop === "without"
                ? false
                : null,
            fixed_yaw: data.yawtype,
          };
          if (configType[0] === true) {
            console.log("Detected day ahead");
            data.day_ahead = configType[1];
          }
          try {
            let resultingId = await addClonedConfiguration(
              castConf,
              cloneId,
              checkedRiskZones
            );
            if (resultingId === undefined) {
              setSidebarIsLoadingProjects(false);
              setIsDuplicating(false);
              throw new Error("Couldn't add configuration - server error");
            }
            let totalSims;
            let simsFinished;
            let statusInterv = setInterval(async () => {
              try {
                let statusResUnparsed = await getConfigurationStatus(
                  resultingId
                );
                if (statusResUnparsed === undefined) {
                  setSidebarIsLoadingProjects(false);
                  setIsDuplicating(false);
                  throw new Error("Couldn't get duplication status");
                }
                let statusRes = await JSON.parse(statusResUnparsed);
                totalSims = statusRes.nb_risk_zones_copied;
                simsFinished = statusRes.nb_simulations_finished;
                setDuplicationCounter([simsFinished, totalSims]);
                if (totalSims === simsFinished) {
                  clearInterval(statusInterv);
                  setIsDuplicating(false);
                  enqueueSnackbar("Succesfully duplicated configuration", {
                    variant: "success",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  });
                  setCurrentItem(["calculationId", resultingId]);
                  setCurrentItem(["elementId", null]);
                  setSidebarOpenItems(resultingId);
                  setModalStep([0]);
                  setStepData(null);
                  setWindData(null);
                  // Ensure that latest risk zones are shown on tree
                  setTimeout(() => {
                    getProjects();
                  }, 1000);
                }
              } catch (error) {
                console.log(error);
                setSidebarIsLoadingProjects(false);
                setIsDuplicating(false);
                clearInterval(statusInterv);
                enqueueSnackbar(error.message, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                });
              }
            }, 4000);
          } catch (error) {
            console.log(error);
            setSidebarIsLoadingProjects(false);
            setIsDuplicating(false);
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
          }
        } else {
          addConfiguration(data);
          setStepData(null);
          setWindData(null);
          setModalStep(["3b"]);
        }
      } else {
        setFileError(true);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    // Clear file upload element
    if (stepData === null) {
      let inputBut = document.getElementById("csv-input");
      inputBut.value = "";
    }
    if (stepData && stepData.fixed_yaw === "fixed") {
      toggleYawDegreeInput(true);
    } else {
      toggleYawDegreeInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData, currentItem]);

  if (isDuplicating) {
    return (
      <div
        id="step-3-container"
        style={
          windData && isClone
            ? {
                gridTemplateColumns: "50% 50%",
                gridTemplateRows: "60% 40%",
                width: "100%",
              }
            : stepData || isClone
            ? {
                gridTemplateColumns: "50% 50%",
                gridTemplateRows: "90% auto",
                width: "100%",
              }
            : { gridTemplateColumns: "100%", width: "50%" }
        }
      >
        <div className="cloning-status-div">
          <div className="duplicating-title">
            {duplicationCounter[1] === 0
              ? "Duplicating configuration, please wait"
              : "Duplicating risk zones, please wait"}
          </div>
          <div className="duplicating-eta">
            {duplicationCounter[1] > 0
              ? duplicationCounter[0] + "/" + duplicationCounter[1]
              : null}
          </div>
          <Loader type="ball-pulse-sync" active />
        </div>
      </div>
    );
  } else {
    return (
      <div
        id="step-3-container"
        style={
          windData && isClone
            ? {
                gridTemplateColumns: "50% 50%",
                gridTemplateRows: "60% 40%",
                width: "100%",
              }
            : stepData || isClone || windData
            ? {
                gridTemplateColumns: "50% 50%",
                gridTemplateRows: "90% auto",
                width: "100%",
              }
            : { gridTemplateColumns: "100%", width: "50%" }
        }
      >
        <div
          id="step-3"
          style={
            isClone && windData
              ? { height: "90%", overflowX: "hidden", overflowY: "auto" }
              : {}
          }
        >
          <div
            className="modal-title"
            style={stepData ? {} : { borderBottom: "solid 1px lightgray" }}
          >
            {isClone ? `Duplicate of ${cloneName}` : "Icefall Configuration"}
          </div>
          {stepData && (
            <div className="date-text">
              created:{" "}
              {`${stepData.date.split("T")[0].split("-")[2]}-${
                stepData.date.split("T")[0].split("-")[1]
              }-${stepData.date.split("T")[0].split("-")[0]}`}
            </div>
          )}
          <form
            style={stepData ? { marginBottom: "10px" } : {}}
            onSubmit={handleSubmit}
          >
            <TextField
              disabled={!!stepData}
              className="input-field"
              variant="outlined"
              label={
                (touched.calcname && errors.calcname) || "Name of configuration"
              }
              error={touched.calcname && errors.calcname !== undefined}
              type="text"
              id="calcname-input"
              name="calcname"
              onChange={handleChange}
              value={values.calcname}
            />
            <p>Position of the rotor</p>
            {touched.yawdegree && errors.yawtype && (
              <p className="error">{errors.yawtype}</p>
            )}
            <div id="rotor-group">
              <div className="radio-group">
                <input
                  disabled={!!stepData}
                  onChange={(e) => {
                    handleChange(e);
                    toggleYawDegreeInput(false);
                  }}
                  style={{ marginLeft: "5%" }}
                  name="yawtype"
                  value="free"
                  error={touched.yawtype && errors.yawtype}
                  type="radio"
                  id="yawfree"
                  checked={values.yawtype === "free" || false}
                />
                <label className="radio-label" hmtlfor="yawfree">
                  Free Yaw
                </label>
                <input
                  disabled={!!stepData}
                  onChange={(e) => {
                    handleChange(e);
                    toggleYawDegreeInput(true);
                  }}
                  name="yawtype"
                  type="radio"
                  id="yawfixed"
                  value="fixed"
                  error={touched.yawtype && errors.yawtype}
                  checked={values.yawtype === "fixed" || false}
                />
                <label className="radio-label" hmtlfor="yawfixed">
                  Fixed Yaw
                </label>
              </div>
              <TextField
                disabled={!!stepData}
                variant="outlined"
                size="small"
                className="input-field degree-input"
                type="text"
                name="yawdegree"
                label={(touched.yawdegree && errors.yawdegree) || "Degrees"}
                error={touched.yawdegree && errors.yawdegree !== undefined}
                onChange={handleChange}
                value={values.yawdegree}
              />
            </div>

            <p>Preventive stop</p>
            {touched.withprevstop && errors.withprevstop && (
              <p className="error">{errors.withprevstop}</p>
            )}
            <div id="preventive-group">
              <div className="radio-group">
                <input
                  disabled={!!stepData}
                  onChange={handleChange}
                  style={{ marginLeft: "5%" }}
                  name="withprevstop"
                  value="with"
                  error={touched.withprevstop && errors.withprevstop}
                  type="radio"
                  id="withprevstop"
                  checked={values.withprevstop === "with" || false}
                />
                <label className="radio-label" hmtlfor="withprevstop">
                  With
                </label>
                <input
                  disabled={!!stepData}
                  onChange={handleChange}
                  style={{ marginLeft: "5%" }}
                  name="withprevstop"
                  type="radio"
                  value="without"
                  error={touched.withprevstop && errors.withprevstop}
                  id="withoutprevstop"
                  checked={values.withprevstop === "without" || false}
                />
                <label className="radio-label" hmtlfor="withoutprevstop">
                  Without
                </label>
              </div>
            </div>
            <div
              style={
                stepData ? { visibility: "hidden" } : { visibility: "visible" }
              }
              id="download-upload-buttons-container"
            >
              <label
                style={
                  isDayAheadLoading ? { display: "none" } : { display: "flex" }
                }
                title="Upload the wind climate data according to the .csv template."
                className="custom-file-upload"
                disabled={!!stepData}
              >
                <input
                  disabled={!!stepData}
                  id="csv-input"
                  accept=".csv"
                  name="uploadedfile"
                  type="file"
                  multiple={false}
                  onChange={(e) => {
                    handleUpload(e);
                  }}
                />
                <img id="uploadicon" src={uploadIcon} alt="uploadcsv" />
                <span>Wind data</span>
              </label>
              {!isDayAheadLoading ? (
                <div className="or-separator">OR</div>
              ) : null}
              {!isDayAheadLoading ? (
                <div
                  onClick={() => {
                    setFileError(false);
                    setIsDayAheadLoading(true);
                    evalDayAhead();
                  }}
                  className="day-ahead"
                >
                  Forecast 5 days
                </div>
              ) : (
                <Loader type="ball-pulse-sync" active />
              )}
            </div>

            {fileError && (
              <p className="error" style={{ fontSize: "1.2rem" }}>
                Please upload a valid file
              </p>
            )}
            {!stepData && (
              <div
                style={
                  isDayAheadLoading
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                id="download-template-container"
              >
                <a
                  id="download-link"
                  href={csvTemplate}
                  download="wind_template.csv"
                  title="Download .csv template for your wind climate data."
                >
                  Download template
                </a>
              </div>
            )}
            {stepData ? (
              <div style={{ display: "grid", height: "60px" }}>
                {!isPDFLoading ? (
                  <div
                    className="download-pdf"
                    onClick={() => getPdf(currentItem.calculationId)}
                  >
                    Download PDF
                  </div>
                ) : (
                  <Loader type="ball-pulse-sync" active />
                )}
              </div>
            ) : null}
            {stepData ? null : (
              <div className="submit-btn-container">
                <button
                  className="next-button"
                  type="submit"
                  disabled={fileError}
                  style={
                    fileError
                      ? { opacity: "0.5", filter: "grayscale(1)" }
                      : null
                  }
                >
                  Save
                </button>
              </div>
            )}
          </form>
        </div>
        {isClone ? (
          <div
            className="risk-zones-checkbox-list"
            style={
              isClone && windData
                ? { height: "100%", minHeight: "150px" }
                : { height: "50%" }
            }
          >
            <div
              className="modal-title"
              style={{ borderBottom: "solid 1px lightgray" }}
            >
              List of Risk Zones to copy
            </div>
            <div className="check-all">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allChecked}
                    onChange={(e) => {
                      let checkBoxes = document.querySelectorAll(
                        ".checkbox-riskzone input"
                      );
                      checkBoxes.forEach((box) => {
                        if (allChecked === false) {
                          if (box.checked === false) {
                            setTimeout(() => {
                              box.click();
                            }, 0);
                          }
                        } else if (allChecked === true) {
                          if (box.checked === true) {
                            setTimeout(() => {
                              box.click();
                            }, 0);
                          }
                        }
                      });
                      setAllChecked(!allChecked);
                    }}
                    value="checkall"
                    name="Check all"
                    color="primary"
                  />
                }
                label={"Check/Uncheck all"}
              />
            </div>
            <div className="risk-checklist">
              <FormControl component="fieldset">
                {riskZonesForCheckList.map((item) => {
                  return (
                    <FormControlLabel
                      key={"Checkbox-" + item.element_id}
                      control={
                        <Checkbox
                          className="checkbox-riskzone"
                          onChange={(e) => {
                            handleRiskzoneCheck(e);
                          }}
                          value={item.element_id}
                          name={item.element_id}
                          color="primary"
                        />
                      }
                      label={item.element_name}
                    />
                  );
                })}
              </FormControl>
            </div>
          </div>
        ) : null}
        <WindTable />
      </div>
    );
  }
}
