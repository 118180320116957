import React, { useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Amplify, Auth } from "aws-amplify";
import transformDataIntoCallBody from "../../../../../service/callConstructor";
import "./Step5.scss";
import Loader from "react-loaders";
import TimerComponent from "./TimerComponent/TimerComponent";
import { snackActions } from "../../../../../utils/SnackbarUtils";
import "loaders.css/src/animations/ball-pulse-sync.scss";

import { apiUrl } from "../../../../../service";

export default function Step5() {
  const stepData = useStoreState((state) => state.stepData);
  const projects = useStoreState((state) => state.projects);
  const isLoadingResults = useStoreState((state) => state.isLoadingResults);
  const setLoadingResults = useStoreActions(
    (actions) => actions.setLoadingResults
  );
  const setResults = useStoreActions((actions) => actions.setResults);
  const results = useStoreState((state) => state.results);
  const setResultScreenStatus = useStoreActions(
    (actions) => actions.setResultScreenStatus
  );
  const setCurrentItem = useStoreActions((actions) => actions.setCurrentItem);
  const setModalStep = useStoreActions((actions) => actions.setModalStep);

  useEffect(() => {
    if (results !== null && results.length > 0) {
    } else if (stepData) {
      clearInterval(window.callRes);
      clearTimeout(window.callTimeout);
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, stepData]);

  // Executes if there are no previous results and does lamda simulations
  async function sendData() {
    try {
      setLoadingResults(true);
      const simulation = transformDataIntoCallBody(
        projects,
        stepData,
        `protected/${stepData.user_id}/${stepData.element_id}`
      );
      let authIdToken = await Auth.currentSession().then((data) => {
        return data.getIdToken().getJwtToken();
      });
      const headers = new Headers({
        "Content-Type": "application/json",
        "x-api-key": "7AYYQNymrB9hY6ePhBakX35h4xXRBLasaWhCl2DA",
        Authorization: authIdToken,
      });
      fetch(`${apiUrl}presence`, {
        method: "POST",
        headers,
        body: JSON.stringify(simulation),
      }).then(() => {
        let resCount = 0;
        window.callTimeout = setTimeout(() => {
          if (
            (stepData.path_type === "Path" && resCount < 9) ||
            (stepData.path_type !== "Path" && resCount < 5)
          ) {
            snackActions.error("Request timeout");
            clearInterval(window.callRes);
            setLoadingResults(false);
          }
        }, 300000);
        window.callRes = setInterval(() => {
          Amplify.Storage.list(stepData.element_id, {
            level: "protected",
            identityId: stepData.user_id,
          }).then((res) => {
            if (res.results.length > 0 && res.results.length < 2) {
              if (res.results[0].key.includes("_exception.txt")) {
                Amplify.Storage.get(res.results[0].key);
                Amplify.Storage.get(res.results[0].key, {
                  level: "protected",
                  identityId: stepData.user_id,
                  download: true,
                })
                  .then((txtFileFromS3) => {
                    return txtFileFromS3.Body.toString();
                  })
                  .then((finallyTxt) => {
                    clearInterval(window.callRes);
                    clearTimeout(window.callTimeout);
                    setLoadingResults(false);
                    setResults(res.results);
                  })
                  .catch((err) => {
                    console.log("Err when getting txt", err);
                    clearInterval(window.callRes);
                    clearTimeout(window.callTimeout);
                    setLoadingResults(false);
                    setResults(res.results);
                  });
              }
            } else if (
              (stepData.calculation_method === "Fixed" ||
                stepData.calculation_method === "Average") &&
              res.results.length > 4
            ) {
              resCount = 9;
              clearInterval(window.callRes);
              clearTimeout(window.callTimeout);
              setLoadingResults(false);
              setResults(res.results);
            } else if (
              stepData.calculation_method === "Parking" &&
              res.results.length > 4
            ) {
              resCount = 9;
              clearInterval(window.callRes);
              clearTimeout(window.callTimeout);
              setLoadingResults(false);
              setResults(res.results);
            } else if (
              stepData.calculation_method === "Path" &&
              res.results.length > 8
            ) {
              resCount = 9;
              clearInterval(window.callRes);
              clearTimeout(window.callTimeout);
              setLoadingResults(false);
              setResults(res.results);
            }
          });
        }, 5000);
      });
    } catch (e) {
      console.error("Error while posting data", e);
      setLoadingResults(false);
    }
  }

  if (isLoadingResults) {
    return (
      <div className="step-5-loader-container">
        <Loader type="ball-pulse-sync" active />
        <div className="step-5-loader-text">
          The simulation is running <TimerComponent />
        </div>
      </div>
    );
  } else if (results !== null && !isLoadingResults) {
    setCurrentItem(["elementId", stepData.element_id]);
    setResultScreenStatus(true);
    setModalStep([4]);
    return <div></div>;
  } else {
    return <div></div>;
  }
}
